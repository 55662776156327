import React, { Fragment, useEffect, useRef, useState } from "react";
//-- imports ------

//---- package ----
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDDMMYY } from "../../../general/helpers/function";

function RmEnquiryFilter({
    filter,
    refresh,
    setFilter,
    setTodate,
    filterRef,
    setRefresh,
    setCategory,
    setFromDate,
    categoryList,
}) {
    const [field, setField] = useState("");
    const [categorys, setCategorys] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateError, setdateError] = useState(false);
    const [categoryDrop, setCategoryDrop] = useState(false);
    const date = new window.Date();

    //---- reset fields ----

    const handleReset = () => {
        setField("");
        setCategorys("");
        setStartDate("");
        setEndDate("");
        setCategoryDrop("");
        setdateError(false);
        setCategory("");
        setTodate("");
        setFromDate("");
    };

    // ---- submit the filter ------

    const handleSubmit = () => {
        if (categorys || startDate || endDate) {
            setRefresh(!refresh);
            setFilter(false);
        }
    };

    // Validation
    const handleValidation = () => {
        if (!startDate && endDate) {
            setdateError(true);
        } else {
            handleSubmit();
        }
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (filterRef.current && filterRef.current.contains(event.target)) {
                    } else {
                        setFilter(false);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref, filterRef, setFilter]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <Fragment>
            <Container ref={wrapperRef} style={{ transform: filter && "scale(1,1)" }}>
                <Top>
                    <Field>
                        <Label>Select Category</Label>
                        <TextField
                            onClick={() => {
                                setField("Category");
                                setCategoryDrop(!categoryDrop);
                            }}
                            className={field === "Category" && "border"}
                        >
                            <Date className={categorys ? "color" : ""}>
                                {categorys ? categorys : "Select"}
                            </Date>
                            <Icon>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcalander-new-dropdown.svg"
                                    alt="arrow"
                                />
                            </Icon>
                        </TextField>
                        {categoryDrop && (
                            <RmList>
                                {categoryList.map((e, index) => (
                                    <List
                                        key={index}
                                        onClick={() => {
                                            setCategory(e.id);
                                            setCategorys(e.title);
                                            setCategoryDrop(false);
                                        }}
                                    >
                                        {e.title}
                                    </List>
                                ))}
                            </RmList>
                        )}
                    </Field>
                </Top>
                <Center>
                    <Line></Line>
                    <span>Date Range</span>
                    <Line></Line>
                </Center>
                <Bottom>
                    <Field>
                        <Label>From</Label>
                        <TextField
                            onClick={() => setField("from_Date")}
                            className={field === "from_Date" ? "border" : ""}
                        >
                            <Date className={startDate ? "color" : ""}>
                                {startDate
                                    ? getDDMMYY(startDate).split("-").join("/")
                                    : "DD/MM/YYY"}
                            </Date>

                            <PikcerDiv>
                                <DatePicker
                                    value=""
                                    selected={startDate}
                                    maxDate={date}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setFromDate(date);
                                    }}
                                />
                            </PikcerDiv>
                        </TextField>
                        {dateError && !startDate && endDate && (
                            <Error>This field is required</Error>
                        )}
                    </Field>
                    <Field>
                        <Label>To</Label>
                        <TextField
                            onClick={() => setField("to_Date")}
                            className={field === "to_Date" ? "border" : ""}
                        >
                            <Date className={endDate ? "color" : ""}>
                                {endDate ? getDDMMYY(endDate).split("-").join("/") : "DD/MM/YYY"}
                            </Date>
                            <PikcerDiv>
                                <DatePicker
                                    value=""
                                    selected={endDate}
                                    maxDate={date}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        setTodate(date);
                                    }}
                                />
                            </PikcerDiv>
                        </TextField>
                    </Field>
                </Bottom>
                <ButtonSection>
                    <Reset
                        onClick={() => {
                            handleReset();
                            setRefresh(!refresh);
                        }}
                    >
                        Reset
                    </Reset>
                    <Submit
                        onClick={() => {
                            handleValidation();
                        }}
                    >
                        Submit
                    </Submit>
                </ButtonSection>
            </Container>
        </Fragment>
    );
}

export default RmEnquiryFilter;
const Container = styled.div`
    position: absolute;
    right: 0;
    top: 55px;
    background-color: #fff !important;
    z-index: 20;
    padding: 20px;
    box-shadow: -10px 40px 75px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    transition: 0.3s;
    transform: scale(0, 0);
`;
const PikcerDiv = styled.div`
    background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcalendericon-new.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 18px;
    cursor: pointer;
    outline: none;
    border: none;
    height: 21px;

    input {
        width: 100%;
        cursor: pointer;
        border: none;
        outline: none;
        caret-color: transparent;
    }
`;

const Line = styled.div`
    height: 1px;
    background-color: #ebeff8;
    width: 100%;
`;
const Top = styled.div``;
const Field = styled.div`
    position: relative;
    margin-bottom: 15px;
    :nth-child(2) {
        margin-bottom: 0;
    }
`;
const Label = styled.span`
    margin-bottom: 10px;
    color: #9c9c9c;
    font-size: 16px;
    display: inline;
    font-family: "outfit_regular";
    display: flex;
    justify-content: space-between;
`;
const TextField = styled.div`
    padding: 8px 15px;
    width: 250px;
    background-color: #f5f7fb;
    border: 1px solid #e6edfb;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.border {
        border: 2px solid #0880e0;
    }
`;
const Date = styled.span`
    display: inline-block;
    color: #9c9c9c;
    font-family: "outfit_regular";
    text-transform: capitalize;
    font-size: 14px;
    &.color {
        color: #0a0a0a;
    }
`;
const Icon = styled.div`
    width: 25px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const Center = styled.div`
    color: #9c9c9c;
    font-family: "outfit_medium";
    display: inline;
    justify-content: center;
    display: flex;
    position: relative;
    align-items: center;
    padding: 12px 0px;
    span {
        color: #9c9c9c;
        display: inline-block;
        text-align: center;
        min-width: 44%;
        font-size: 16px;
    }
`;
const Bottom = styled.div`
    position: relative;
`;
const ButtonSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
`;
const Reset = styled.div`
    border: 1px solid #0880e0;
    background-color: #fff;
    border-radius: 2px;
    color: #0880e0;
    padding: 8px 0px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
`;
const Submit = styled.div`
    border: 1px solid #0880e0;
    background-color: #fff;
    border-radius: 2px;
    color: #fff;
    background-color: #0880e0;
    padding: 8px 0px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
`;
const RmList = styled.div`
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 30;
    box-shadow: -10px 40px 75px rgb(0 0 0 / 15%);
    top: 84px;
    overflow-y: scroll;
    max-height: 170px;
    border-radius: 5px;
    padding: 10px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const List = styled.div`
    width: 100%;
    padding: 10px 9px;
    color: black;
    font-size: 16px;
    background-color: #f5f7fb;
    margin-bottom: 10px;
    cursor: pointer;
`;
const Error = styled.span`
    display: inline-block;
    color: red;
    font-size: 13px;
`;
