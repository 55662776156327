//---- imports------
import React, { useState, useContext, useEffect } from "react";
import { accountsConfig, supportsConfig } from "../../../AxiosConfig";
import ButtonLoading from "../general/helpers/ButtonLoading";
import { Context } from "../../contexts/Store";

//--- packages ----
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDDMMYY, getYYMMDD } from "../general/helpers/function";
import $ from "jquery";

export default function DownloadFormat({
    customerName,
    handleStatus,
    setMode,
    isMode,
    RmName,
    status,
    id,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isPdf, setPdf] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [field, setField] = useState("pdf");
    const [current, setCurrent] = useState("pending");

    const date = new window.Date();

    //---- clear all the states--
    const handleClear = () => {
        setEndDate("");
        setPdf(true);
        setStartDate("");
        setField("pdf");
        setIsLoading(false);
        setMode(false);
        setCurrent("pending");
    };

    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    const downloadFile = function () {
        if (status === "Enquiries") {
            setIsLoading(true);
            supportsConfig({
                url: `api/v1/enquiries/export-enquiry-reports/`,
                method: "GET",

                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    ...(isPdf && { pdf_required: isPdf }),
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                    enquiry_status: current,
                },

                responseType: "blob", // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", isPdf ? "Enquiries.pdf" : "Enquiries.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
                handleClear();
            });
        } else if (status === "RmProfiles") {
            setIsLoading(true);
            accountsConfig({
                url: `api/v1/users/export-rm-detail-reports/`,
                method: "GET",

                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    ...(isPdf && { pdf_required: isPdf }),
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                },

                responseType: "blob", // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", isPdf ? "RmProfiles.pdf" : "RmProfiles.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
                handleClear();
            });
        } else if (status === "Customer") {
            setIsLoading(true);
            accountsConfig({
                url: `api/v1/users/export-customer-detail-reports/`,
                method: "GET",

                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    ...(isPdf && { pdf_required: isPdf }),
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                },

                responseType: "blob", // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", isPdf ? "Customer.pdf" : "Customer.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
                handleClear();
            });
        } else if (status === "RmfrofileSinglePage") {
            setIsLoading(true);
            supportsConfig({
                url: `api/v1/enquiries/export-single-rm-enquiry-report/${id}`,
                method: "GET",

                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    ...(isPdf && { pdf_required: isPdf }),
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                },

                responseType: "blob", // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", isPdf ? `RM-${RmName}.pdf` : `RM-${RmName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
                handleClear();
            });
        } else if (status === "Rm_Enquiries") {
            setIsLoading(true);
            supportsConfig({
                url: `api/v1/enquiries/rm/export/enquiry-list/`,
                method: "GET",

                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    ...(isPdf && { pdf_required: "True" }),
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                    enquiry_status: current,
                    assigned_status: handleStatus,
                },

                responseType: "blob", // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", isPdf ? "Enquiries.pdf" : "Enquiries.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
                handleClear();
            });
        } else {
            setIsLoading(true);
            supportsConfig({
                url: `api/v1/enquiries/export-customer-enquiry-report/${id}`,
                method: "GET",

                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    ...(isPdf && { pdf_required: isPdf }),
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                },

                responseType: "blob", // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    isPdf ? `Customer-${customerName}.pdf` : `Customer-${customerName}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
                handleClear();
            });
        }
    };
    // Outside Scroll
    useEffect(() => {
        if (isMode) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isMode]);

    return (
        <MainContainer>
            {isMode && <Overlay></Overlay>}
            <BackContainer style={{ transform: isMode && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <TopContainer>
                            <CoverText>Download Format</CoverText>
                            <ImageContainer onClick={handleClear}>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                    alt="Cross"
                                />
                            </ImageContainer>
                        </TopContainer>
                        {status === "Enquiries" || status === "Rm_Enquiries" ? (
                            <Status>
                                <span>Status</span>
                                <Ul>
                                    <Li
                                        onClick={() => setCurrent("pending")}
                                        className={current === "pending" && "color"}
                                    >
                                        {current === "pending" ? (
                                            <Round>
                                                <small></small>
                                            </Round>
                                        ) : (
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/not-active.svg"
                                                alt="round"
                                            />
                                        )}
                                        <p>Pending</p>
                                    </Li>
                                    <Li
                                        onClick={() => setCurrent("active")}
                                        className={current === "active" && "color"}
                                    >
                                        {current === "active" ? (
                                            <Round>
                                                <small></small>
                                            </Round>
                                        ) : (
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/not-active.svg"
                                                alt="round"
                                            />
                                        )}

                                        <p>Active</p>
                                    </Li>

                                    <Li
                                        onClick={() => setCurrent("closed")}
                                        className={current === "closed" && "color"}
                                    >
                                        {current === "closed" ? (
                                            <Round>
                                                <small></small>
                                            </Round>
                                        ) : (
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/not-active.svg"
                                                alt="round"
                                            />
                                        )}
                                        <p>Closed</p>
                                    </Li>
                                </Ul>
                            </Status>
                        ) : (
                            ""
                        )}

                        <Center>
                            <span>Date Range</span>
                        </Center>
                        <Bottom>
                            <Field>
                                <Label>From</Label>
                                <TextField className={field === "from_Date" && "border"}>
                                    <Date className={startDate && "color"}>
                                        {startDate
                                            ? getDDMMYY(startDate).split("-").join("/")
                                            : "DD/MM/YYY"}
                                    </Date>

                                    <PikcerDiv>
                                        <DatePicker
                                            value=""
                                            selected={startDate}
                                            maxDate={date}
                                            onChange={(date) => setStartDate(date)}
                                        />
                                    </PikcerDiv>
                                </TextField>
                            </Field>
                            <Field>
                                <Label>To</Label>
                                <TextField>
                                    <Date className={endDate && "color"}>
                                        {endDate
                                            ? getDDMMYY(endDate).split("-").join("/")
                                            : "DD/MM/YYY"}
                                    </Date>
                                    <PikcerDiv>
                                        <DatePicker
                                            value=""
                                            selected={endDate}
                                            maxDate={date}
                                            onChange={(date) => setEndDate(date)}
                                        />
                                    </PikcerDiv>
                                </TextField>
                            </Field>
                        </Bottom>
                        <Content>Select your format of download</Content>
                        <SelectField>
                            <Download
                                onClick={() => {
                                    setPdf(true);
                                    setField("pdf");
                                }}
                                className={field === "pdf" && "border"}
                            >
                                <p>Download PDF</p>
                                <Image>
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fpdf-non-background.svg"
                                        alt="pdf"
                                    />
                                </Image>
                            </Download>
                            <Download
                                onClick={() => {
                                    setPdf(false);
                                    setField("xlsx");
                                }}
                                className={field === "xlsx" && "border"}
                            >
                                <p>Download XLS</p>
                                <Image>
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fxls-background-removed.svg"
                                        alt="xlsx"
                                    />
                                </Image>
                            </Download>
                        </SelectField>

                        <CoverSubmit>
                            <Cancel onClick={handleClear}>Cancel</Cancel>
                            <Submit onClick={downloadFile}>
                                {isLoading ? <ButtonLoading /> : "Download"}
                            </Submit>
                        </CoverSubmit>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const Bottom = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const Date = styled.span`
    display: inline-block;
    cursor: default;
    color: #9c9c9c;
    font-family: "outfit_regular";
    text-transform: capitalize;
    font-size: 14px;
    &.color {
        color: #0a0a0a;
    }
`;
const PikcerDiv = styled.div`
    background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcalendericon-new.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 18px;
    cursor: pointer;
    outline: none;
    border: none;
    height: 21px;

    input {
        width: 100%;
        cursor: pointer;
        border: none;
        outline: none;
        caret-color: transparent;
    }
`;
const TextField = styled.div`
    padding: 18px 15px;
    width: 100%;
    background-color: #f5f7fb;
    border: 1px solid #e6edfb;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Field = styled.div`
    position: relative;
    @media all and (max-width: 480px) {
        width: 100%;
        :nth-child(1) {
            margin-bottom: 10px;
        }
    }

    width: 48%;
    :nth-child(2) {
        margin-bottom: 0;
    }
`;
const Content = styled.p`
    color: #747474;
    font-size: 16px;
    font-family: "outfit_medium";
    margin: 20px 0px;
    @media all and (max-width: 768px) {
        display: flex;
        justify-content: center;
    }
`;

const Center = styled.div`
    color: #9c9c9c;
    font-family: "outfit_medium";
    display: inline;
    display: flex;
    position: relative;
    align-items: center;
    padding: 12px 0px;
    span {
        color: #747474;
        display: inline-block;
        text-align: center;
        min-width: 44%;
        font-size: 16px;
        display: inline-flex;
        font-family: "outfit_medium";
    }
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    background: #fff;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    overflow-y: hidden;
    overflow-y: scroll;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const MainContainer = styled.div``;
const CoverText = styled.h3`
    color: #1a6eb0;
    margin-bottom: 5px;
`;
const Container = styled.div`
    padding: 30px;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 25px;
    margin-bottom: 15px;
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const Label = styled.div`
    color: #929292;
    margin-bottom: 10px;
    font-family: "outfit_medium";
    font-size: 15px;
`;

const CoverSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
    @media all and (max-width: 640px) {
        justify-content: center;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const Cancel = styled.div`
    background: #fff;
    padding: 10px 38px;
    width: 100%;
    height: 45px;
    color: #0880e0;
    border: 1px solid #0880e0;
    margin-right: 15px;
    cursor: pointer;
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
`;

const Submit = styled.div`
    background-color: #0880e0;
    color: #fff;
    width: 150px;
    height: 45px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const SelectField = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
const Download = styled.div`
    display: flex;
    cursor: pointer;
    padding: 20px 48px;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    &.border {
        border: 2px solid #0884df;
    }
    p {
        color: #929292 !important;
        margin-right: 10px;
        cursor: pointer;
    }
    @media all and (max-width: 768px) {
        width: 60%;
        justify-content: center;
        :nth-child(1) {
            margin-bottom: 10px;
        }
    }
    @media all and (max-width: 570px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 95%;
        padding: 20px 40px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        padding: 20px 30px;
    }
`;
const Image = styled.div`
    width: 45px;
    img {
        width: 100%;
        display: block;
    }
`;
const Status = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    span {
        color: #747474;
        font-family: "outfit_medium";
        display: inline;
        display: flex;
        position: relative;
        align-items: center;
        padding: 12px 0px;
    }
`;
const Ul = styled.ul`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
    @media all and (max-width: 360px) {
        flex-direction: column;
    }
`;
const Li = styled.li`
    border: 1px solid #e3e3e3;
    padding: 13px 20px;
    display: flex;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 31%;
    cursor: pointer;
    &.color {
        border: 1px solid #0884df;
        background-color: #f5f7fb;
    }
    @media all and (max-width: 640px) {
        width: 45%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }

    p {
        color: #2d2d2d;
    }
    img {
        margin-right: 7px;
    }
`;
const Round = styled.div`
    width: 15px;
    height: 15px;
    border: 1px solid #9d9d9d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    small {
        background-color: #0884df;
        width: 7px;
        height: 7px;
        border-radius: 50%;
    }
`;
