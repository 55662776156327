import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

// packages
import styled from "styled-components";
import ButtonLoading from "../../../general/helpers/ButtonLoading";
import { truncate } from "../../../general/helpers/function";

function RmEnquiryCard({ item, handleStatus, acceptEnquiry, button, updateStatus }) {
    const [id, setId] = useState("");

    return (
        <>
            <Fragment>
                <Container
                    onClick={() => updateStatus(item.enquiry_status)}
                    to={handleStatus === "assigned" ? `/rm/enquiry/${item.id}` : ""}
                >
                    <ContanerList type="Enquiry_no">
                        {!item.is_rm_read && <Arrow></Arrow>}
                        {item.enquiry_number}
                    </ContanerList>

                    <ContanerList type="Enquiry">{truncate(item.title, 15)}</ContanerList>
                    <ContanerList type="Category">
                        <span>{truncate(item.category_title, 15)}</span>
                    </ContanerList>
                    <ContanerList type="Latest">
                        {item.latest_reply.message
                            ? truncate(item.latest_reply?.message, 15)
                            : "--"}
                    </ContanerList>
                    <ContanerList type="Description">
                        <span>{item.description ? truncate(item.description, 20) : "--"}</span>
                    </ContanerList>
                    <ContanerList
                        className={item.enquiry_status}
                        type={handleStatus === "assigned" ? "Status" : ""}
                    >
                        <span>{item.enquiry_status}</span>
                    </ContanerList>
                    {handleStatus === "not-assigned" && (
                        <ContanerList type="Status">
                            <span
                                onClick={() => {
                                    acceptEnquiry(item.id);
                                    setId(item.id);
                                }}
                                className="Accept"
                            >
                                {id === item.id && button ? <ButtonLoading /> : "   Accept"}
                            </span>
                        </ContanerList>
                    )}
                </Container>
            </Fragment>
        </>
    );
}

export default RmEnquiryCard;
const ContanerList = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;

    font-size: 17px;
    font-family: "outfit_regular";
    display: flex;
    width: 16.6%;
    justify-content: ${({ type }) => (type === "Status" ? "flex-end" : "")};
    &.pending {
        color: #ffa826;
    }
    &.active {
        color: #009262;
    }
    span {
        &.Accept {
            color: #fff;
            background: #087ee1;
            cursor: pointer;
            border-radius: 4px;
            font-size: 16px;
            width: 75px;
            height: 32px;
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;
const Arrow = styled.div`
    margin-right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgb(36, 128, 224);
`;
const Container = styled(Link)`
    display: flex;
    align-items: center;
    padding: 26px 0px;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
    transition: background-color 0.2s ease;

    /* &.hover {
        background-color: #0880e0;
        border-radius: 5px;
        ${ContanerList} {
            color: #fff;
        }
        ${Arrow} {
            background-color: #fff;
        }
    } */

    @media all and (max-width: 1280px) {
        min-width: 1280px;
    }
`;
