import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { accountsConfig, supportsConfig } from "../../../AxiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoading from "../general/helpers/ButtonLoading";
import $ from "jquery";

function AssignModal({
    getId,
    isModal,
    refresh,
    setModal,
    enqNumber,
    currentRm,
    setRefresh,
    setrmDetails,
    updateStatus,
    handleAssignModal,
    isQuote
}) {
    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;
    const [error, setError] = useState(false);
    const [rmList, setRmList] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [fieldName, setFieldName] = useState(null);
    const [border, setBorder] = useState("");
    const [categoryUsername, setCategoryUsername] = useState([]);
    const [description, setDescription] = useState("");

    const onClear = () => {
        setError(false);
        setRmList([]);
        setDropDown(false);
        setFieldName(null);
        setBorder("");
        setCategoryUsername([]);
        setDescription("");
        setButtonLoader(false);
    };
    //-------------assign rm api---------------------
    const assignHandler = (status) => {
        if (categoryUsername && description) {
            setButtonLoader(true);
            let url = `api/v1/enquiries/${status}/${getId}/`;
            if(isQuote){
                url = `api/v1/tenders/admin/quotations/${getId}/assign-rm`;
            }
            supportsConfig
                .post(
                    url,
                    {
                        assignee_id: categoryUsername.username,
                        description: description,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setError("");
                        setDescription("");
                        setError(false);
                        handleAssignModal();
                        setCategoryUsername(null);
                        setRefresh(!refresh);
                        setButtonLoader(false);
                        onClear();
                        if (setrmDetails) {
                            setrmDetails(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setButtonLoader(false);
                });
        } else {
            setError(true);
        }
    };

    // Outside Scroll
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    //---------------------------get Rm list---------------------------------------
    useEffect(() => {
        isModal &&
            accountsConfig
                .get("api/v1/users/get-rm-list/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setRmList(data.data);
                    } else {
                        setError(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
    }, [access_token, isModal]);

    return (
        <MainContainer>
            {isModal ? <Overlay onClick={() => handleAssignModal()}></Overlay> : ""}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <TopSection>
                            <CoverText>
                                <Text>{updateStatus === "assign" ? "Assign RM" : "Update RM"}</Text>
                                <ImageContainer
                                    onClick={() => {
                                        handleAssignModal();
                                        onClear();
                                    }}
                                >
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                        alt="Images"
                                    />
                                </ImageContainer>
                            </CoverText>
                            <LabelText>{isQuote ? "Qtn" : "Enq"} : {enqNumber}</LabelText>
                        </TopSection>
                        {currentRm && (
                            <LabelText className="rm">Current Rm : {currentRm}</LabelText>
                        )}
                        <Cover>
                            <Label>Select new RM</Label>
                            <SelectField
                                className={border === "select" && "border"}
                                onClick={() => {
                                    setBorder("select");
                                    setDropDown(!dropDown);
                                }}
                            >
                                <Left className={!fieldName && "name"}>
                                    {fieldName ? (
                                        <Fragment>
                                            {fieldName?.photo ? (
                                                <span
                                                    style={{
                                                        backgroundImage: `url(${fieldName?.photo})`,
                                                    }}
                                                ></span>
                                            ) : (
                                                <span>
                                                    <small>{fieldName?.name?.charAt(0)}</small>
                                                </span>
                                            )}
                                            <p>{fieldName.name}</p>
                                        </Fragment>
                                    ) : (
                                        "Select"
                                    )}
                                </Left>
                                <Right>
                                    <ArrowContainer>
                                        <img
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fassgin-rm-new-arrow.svg"
                                            alt="arrow"
                                        />
                                    </ArrowContainer>
                                </Right>
                            </SelectField>
                            {error && categoryUsername.length <= 0 && (
                                <Error>This field is required</Error>
                            )}
                            {dropDown && (
                                <RmList>
                                    {rmList.map((e, index) => (
                                        <List
                                            key={index}
                                            onClick={() => {
                                                setCategoryUsername(e);
                                                setDropDown(false);
                                                setFieldName(e);
                                            }}
                                        >
                                            <p> {e.name}</p>
                                        </List>
                                    ))}
                                </RmList>
                            )}
                        </Cover>
                        <Cover>
                            <Label>Description</Label>
                            <TextArea
                                className={border === "category" && "border"}
                                onClick={() => setBorder("category")}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    setError(false);
                                }}
                                value={description}
                            />
                            {error && !description && <Error>This field is required</Error>}
                        </Cover>{" "}
                        <CoverSubmit>
                            <Cancel
                                onClick={() => {
                                    setModal(false);
                                    onClear();
                                }}
                            >
                                Cancel
                            </Cancel>
                            <Submit
                                onClick={() => {
                                    updateStatus === "assign"
                                        ? assignHandler("assign-rm")
                                        : assignHandler("update-rm");
                                }}
                            >
                                {buttonLoader ? (
                                    <ButtonLoading />
                                ) : updateStatus === "assign" ? (
                                    "Assign"
                                ) : (
                                    "Update"
                                )}
                            </Submit>
                        </CoverSubmit>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}
export default AssignModal;
const MainContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    background: #fff;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow-y: hidden;
    @media all and (max-width: 640px) {
        max-width: 500px;
    }
`;
const Container = styled.div`
    padding: 30px;
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    & img {
        display: block;
        width: 100%;
    }
`;
const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ebebeb;
`;
const LabelText = styled.div`
    color: #bfbfbf;
    font-size: 14px;
    margin-bottom: 15px;
    &.rm {
        margin-bottom: 0px;
        padding-top: 15px;
    }
`;
const Text = styled.div`
    color: #1a6eb0;
    font-size: 20px;
    margin-bottom: 5px;
`;

const CoverText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Label = styled.div`
    color: #929292;
    font-family: "outfit_regular";
    font-size: 16px;
    margin-bottom: 10px;
`;

const Cover = styled.div`
    padding-top: 25px;
    position: relative;
`;
const SelectField = styled.div`
    background-color: #f5f7fb;
    border: 1px solid #e6edfb;

    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 1px;
    &.border {
        border: 2px solid #0884df;
    }
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    &.name {
        color: #929292;
    }
    small {
        color: #fff;
        text-align: center;
        text-transform: capitalize;
    }
    span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #0880e0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 8px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    p {
        color: #707070;
        font-size: 16px;
    }
`;
const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ArrowContainer = styled.div`
    width: 25px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const TextArea = styled.textarea`
    resize: none;
    font-size: 14px;
    color: #707070 !important;
    font-size: 16px;
    background-color: #f5f7fb;
    width: 100%;
    height: 130px;
    padding: 10px;
    outline: unset;
    border: 1px solid #e6edfb;
    padding: 10px 20px;
    &.border {
        border: 2px solid #0884df;
    }

    ::-webkit-scrollbar {
        display: none;
    }
`;
const Cancel = styled.div`
    background: #fff;
    height: 45px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0880e0;
    border: 1px solid #0880e0;
    margin-right: 20px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
`;
const Submit = styled.div`
    background: #0880e0;
    height: 45px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const CoverSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const RmList = styled.div`
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 30;
    box-shadow: -10px 40px 75px rgb(0 0 0 / 15%);
    top: 110px;
    overflow-y: scroll;
    max-height: 170px;
    border-radius: 5px;
    padding: 10px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const List = styled.div`
    width: 100%;
    padding: 10px 9px;
    color: #9c9c9c;
    font-size: 16px;
    background-color: #f5f7fb;
    margin-bottom: 10px;
    cursor: pointer;
`;
const Error = styled.p`
    color: red;
    font-size: 12px;
    margin-top: 5px;
`;
