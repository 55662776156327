import React, { useContext } from "react";
import { Context } from "../../contexts/Store";

const RmRoute = ({ children }) => {
    const { state } = useContext(Context);
    const role = state.user_data.role;

    return role.includes("relationship_manager") ? children : <h1>404</h1>;
};

export default RmRoute;
