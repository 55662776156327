//---- imports------
import React, { useEffect, useRef } from "react";

//--- packages ----
import styled from "styled-components";
import blue from "../../../assets/images/blue-tick-sucess.svg";

export default function Forgot({ isMode, setMode, message }) {
    const wrapperRef = useRef(null);

    //----------------outside click------------------------
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMode(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    return (
        <MainContainer>
            {isMode && <Overlay></Overlay>}
            <BackContainer style={{ transform: isMode && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <h1>Get a new password !</h1>
                        <p>
                            No worries, we’ll send you reset instructions. Check your email to get a
                            new password.
                        </p>
                        <Bottom>
                            <img src={blue} alt="tick" />

                            <span>{message}</span>
                        </Bottom>
                        <Button href="http://www.gmail.com" target="_blank">
                            <span>Check your email</span>
                        </Button>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

const Bottom = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 20px;
        margin-right: 5px;
    }
    span {
        color: #707070;
        font-size: 16px;
        font-family: "outfit_light";
    }
`;

const Button = styled.a`
    background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;

    @media all and (max-width: 480px) {
        height: 40px;
    }
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 500px;
    background: #fff;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    border-radius: 4px;

    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    overflow-y: hidden;
    overflow-y: scroll;
`;
const MainContainer = styled.div``;

const Container = styled.div`
    padding: 30px;
    color: #606060;
    h1 {
        font-size: 24px;
        font-family: "outfit_medium";

        @media all and (max-width: 480px) {
            font-size: 20px;
        }
    }
    p {
        font-size: 16px;

        margin: 10px 0px;
        @media all and (max-width: 480px) {
            font-size: 15px;
        }
    }
`;
