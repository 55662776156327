import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

//Packages
import SectionLoader from "../../../general/helpers/SectionLoader";
import { getYYMMDD } from "../../../general/helpers/function";
import EnquiryAcceptModal from "../modal/EnquiryAcceptModal";
import { supportsConfig } from "../../../../../AxiosConfig";
import DownloadFormat from "../../../modal/DownloadFormat";
import RmEnquiryFilter from "../includes/RmEnquiryFilter";
import NodataFound from "../../../includes/NodataFound";
import RmEnquiryCard from "../includes/RmEnquiryCard";
import { Context } from "../../../../contexts/Store";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
//Imports
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import axios from "axios";

function RmEnquiries() {
    const [handleStatus, setHandleStatus] = useState("assigned");
    const [categoryList, setCategoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [category, setCategory] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [filter, setFilter] = useState(false);
    const [button, setButton] = useState(false);
    const [enquiry, setEnquiry] = useState([]);
    const [isMode, setMode] = useState(false);
    const [isShow, setShow] = useState(false);
    const [searchParams] = useSearchParams();
    const [todate, setTodate] = useState("");
    const [value, setValue] = useState("");
    const latestCancelRm = useRef(null);

    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    const filterRef = useRef();
    const handleSuccessModal = () => {
        setShow(!isShow);
    };
    //---- ststus upfation----
    const updateStatus = (item) => {
        dispatch({
            type: "UPDATE_ENQUIRY_STATUS",
            enquiry_status: {
                status: item,
            },
        });
    };

    //---------- debounce -----------
    const handleText = debounce((text) => {
        setValue(text);
    }, 600);
    //------ filter ------
    const handleFilter = () => {
        setFilter(!filter);
    };

    //--- pagination---
    useEffect(() => {
        const setInitialSearch = () => {
            const page = searchParams.get("page");
            setCurrentPage(page ? page : 1);
        };
        setInitialSearch();
    }, [searchParams]);

    //------------Category List --------------
    useEffect(() => {
        supportsConfig
            .get("api/v1/enquiries/enquiry-category/list/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setCategoryList(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----- Rm Enquiries api   -------

    useEffect(() => {
        const fetchRmEnquiry = () => {
            setLoading(true);
            const cancelTokenSource = axios.CancelToken.source();
            latestCancelRm.current = cancelTokenSource;
            supportsConfig
                .get("api/v1/enquiries/rm/enquiry-list/", {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        q: value,
                        // enquiry_status: "",
                        category_id: category,
                        from_date: getYYMMDD(fromDate),
                        to_date: getYYMMDD(todate),
                        page: currentPage,
                        pagination: "pagination",
                        assigned_status: handleStatus === "assigned" ? "" : "not_assigned",
                    },
                    cancelToken: cancelTokenSource.token,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setEnquiry(data.data);
                        setPagination(data.pagination_data);
                        setLoading(false);
                    } else {
                        if (StatusCode === 6001) {
                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        if (latestCancelRm.current) {
            latestCancelRm.current.cancel("Previous request canceled");
        }
        fetchRmEnquiry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleStatus, value, refresh, currentPage]);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }
    //--- enquiry accept api -----

    const acceptEnquiry = (item) => {
        setButton(true);
        supportsConfig
            .get(`api/v1/enquiries/rm/enquiry-accept/${item}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    handleSuccessModal();
                    setRefresh(!refresh);
                    setButton(false);
                } else {
                    setButton(false);
                }
            })

            .catch((error) => {
                console.log(error);
                setButton(false);
            });
    };

    return (
        <Fragment>
            <Container id="main">
                <SubConatiner>
                    <EnquiryTopBar>
                        <EnquiryLeft>
                            <h2>Enquiries</h2>
                        </EnquiryLeft>
                        <Enquiryright>
                            <InputField>
                                <SearchIcon>
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fsearchicon-new-black.svg"
                                        alt="search"
                                    />
                                </SearchIcon>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => handleText(e.target.value)}
                                />
                            </InputField>
                            <FilterField
                                ref={filterRef}
                                className={filter ? "filter" : ""}
                                onClick={() => handleFilter()}
                            >
                                <span className={filter ? "filter" : ""}>Filter</span>
                                <FilterIcon>
                                    {filter ? (
                                        <img
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Ffilter-new-white.svg"
                                            alt="filter"
                                        />
                                    ) : (
                                        <img
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Ffiter-new.svg"
                                            alt="filter"
                                        />
                                    )}
                                </FilterIcon>
                            </FilterField>

                            <RmEnquiryFilter
                                categoryList={categoryList}
                                setCategory={setCategory}
                                setFromDate={setFromDate}
                                setRefresh={setRefresh}
                                filterRef={filterRef}
                                setFilter={setFilter}
                                setTodate={setTodate}
                                refresh={refresh}
                                filter={filter}
                            />
                        </Enquiryright>
                    </EnquiryTopBar>
                    <StatusSection>
                        <LeftStatus>
                            <Unorder>
                                <List
                                    type={handleStatus === "assigned" ? "cl" : ""}
                                    onClick={() => setHandleStatus("assigned")}
                                >
                                    Assigned
                                </List>
                                <List
                                    type={handleStatus === "not-assigned" ? "ac" : ""}
                                    onClick={() => setHandleStatus("not-assigned")}
                                >
                                    Not-Assigned
                                </List>
                            </Unorder>
                        </LeftStatus>
                        <RightStatus>
                            <Download onClick={() => setMode(true)}>
                                Download Enquiry
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FdownloadEnquiry-new.svg"
                                    alt="Download"
                                />
                            </Download>
                        </RightStatus>
                    </StatusSection>
                    <EnquiryData>
                        <EnquiryList>
                            <ListHead>
                                <HeadItem type="Enquiry_no">Enquiry No.</HeadItem>
                                <HeadItem type="Enquiry">Enquiry</HeadItem>
                                <HeadItem type="Category">Category</HeadItem>
                                <HeadItem type="Latest">Latest Reply</HeadItem>
                                <HeadItem type="Description">Description</HeadItem>
                                <HeadItem type={handleStatus === "assigned" ? "Status" : ""}>
                                    Status
                                </HeadItem>
                                {handleStatus === "not-assigned" && (
                                    <HeadItem type="Status">Action</HeadItem>
                                )}
                            </ListHead>
                            {loading ? (
                                <SectionLoader />
                            ) : enquiry?.length >= 1 ? (
                                enquiry.map((item) => {
                                    return (
                                        <RmEnquiryCard
                                            key={item.id}
                                            item={item}
                                            button={button}
                                            handleStatus={handleStatus}
                                            acceptEnquiry={acceptEnquiry}
                                            updateStatus={updateStatus}
                                        />
                                    );
                                })
                            ) : (
                                <NodataFound />
                            )}
                        </EnquiryList>

                        {pagination && pagination.total_pages > 1 && enquiry?.length > 0 && (
                            <PaginationCover>
                                <PaginationContainer>
                                    <PaginationText>
                                        Showing Enquiries : {pagination?.first_item} -{" "}
                                        {pagination?.last_item} of {pagination?.total_items}{" "}
                                        Enquiries
                                    </PaginationText>
                                    <ReactPaginate
                                        previousLabel={""}
                                        nextLabel={""}
                                        pageCount={pagination.total_pages}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                    />
                                </PaginationContainer>
                            </PaginationCover>
                        )}
                    </EnquiryData>
                </SubConatiner>
            </Container>

            <DownloadFormat
                handleStatus={handleStatus}
                status="Rm_Enquiries"
                setMode={setMode}
                isMode={isMode}
            />
            <EnquiryAcceptModal isShow={isShow} handleSuccessModal={handleSuccessModal} />
        </Fragment>
    );
}

export default RmEnquiries;
const Container = styled.div`
    /* ::-webkit-scrollbar {
        display: none;
    } */
`;
const EnquiryList = styled.div`
    @media all and (max-width: 1280px) {
        overflow-x: scroll;
    }
`;
const SubConatiner = styled.div`
    width: 100%;
`;

const EnquiryTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
    @media all and (max-width: 480px) {
        padding: 10px 0px;
    }
`;

const StatusSection = styled.div`
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    margin-bottom: 10px;
    @media all and (max-width: 480px) {
        flex-direction: column-reverse;
    }
`;

const EnquiryData = styled.div`
    overflow-x: hidden;
    background-color: #fff;
    min-height: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    justify-content: space-between;

    display: flex;
    flex-direction: column;
`;

const EnquiryLeft = styled.div`
    @media all and (max-width: 640px) {
        margin-bottom: 10px;
    }
    h2 {
        font-size: 23px;
        color: #0a0a0a;
        font-family: "outfit_medium";
        display: inline-block;
    }
`;
const Enquiryright = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    @media all and (max-width: 640px) {
        width: 100%;
        justify-content: space-between;
    }
    @media all and (max-width: 370px) {
        justify-content: unset;
    }

    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const InputField = styled.div`
    display: flex;
    background-color: #fff;
    padding: 10px 15px;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    @media all and (max-width: 640px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        margin-right: 0;
    }
    @media all and (max-width: 370px) {
        margin-right: 20px;
    }
    input {
        border: none;
        outline: none;
        -webkit-appearance: none;
        width: 200px;
        font-size: 15px;
        ::placeholder {
            color: #747474;
            font-size: 15px;
            font-family: "outfit_light";
        }
        @media all and (max-width: 640px) {
            width: 100%;
        }
        @media all and (max-width: 480px) {
            width: 100%;
        }
    }
`;
const FilterField = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #0a0a0a;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const FilterIcon = styled.div`
    width: 20px;

    img {
        width: 100%;
        display: block;
        height: 100%;
    }
`;
const SearchIcon = styled.div`
    width: 22px;
    margin-right: 10px;
    display: flex;
    img {
        width: 100%;
        display: block;
        height: 100%;
    }
    input {
    }
`;
const ListHead = styled.div`
    display: flex;
    padding: 25px 0px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    z-index: 5;
    & ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        min-width: 1280px;
    }
`;
const RightStatus = styled.div`
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const Download = styled.button`
    display: flex;
    align-items: center;
    color: #004a85;
    font-size: 14px;
    background-color: #e2eaf9;
    padding: 10px 10px;
    justify-content: center;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    img {
        margin-left: 8px;
        width: 15px;
        height: 100%;
    }
`;

const LeftStatus = styled.div``;
const Unorder = styled.ul`
    display: flex;
    align-items: center;
`;
const List = styled.li`
    font-family: "outfit_regular";
    font-size: 18px;
    cursor: pointer;
    position: relative;
    margin-right: 40px;
    :nth-child(2) {
        margin-right: 0;
    }

    ::before {
        content: "";
        position: ${({ type }) =>
            type === "ac" ? "absolute" : type === "cl" ? "absolute" : "relative"};
        left: 0px;
        height: 4px;
        background-color: #0880e0;
        width: 100%;
        bottom: -9px;
    }

    :nth-child(3) {
        margin-right: 0;
    }
    color: ${({ type }) =>
        type === "ac"
            ? "#0884DF"
            : type === "cl"
            ? "#0884DF"
            : type === "pd"
            ? "#0884DF"
            : "#8B8B8B"};
`;
const HeadItem = styled.div`
    display: flex;
    color: #747474;
    font-family: "outfit_regular";
    font-size: 17px;
    width: 16.6%;
    justify-content: ${({ type }) => (type === "Status" ? "flex-end" : "")};
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 15px;
    position: relative;
    bottom: 0;
    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #48538d;
    font-size: 14px;
`;
const PaginationCover = styled.div``;
