import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        isLogin: false,
        access_token: "",
        email: "",
        get_enquiry_id: "",
        password: "",
        user_id: "",
        role: "",
        click_event: "",
        enquiry_id: "",
        rm_name: "",
    },

    customer_data: {
        customer_username: "",
        customer_name: "",
    },

    aside_status: {
        sidebar_status: false,
    },
    enquiry_status: {
        status: "pending",
    },
    scroll: {
		scroll_id: "",
		scroll_top: "",
	},
};
const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
