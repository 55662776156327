import React, { useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";

function EnquiryAcceptModal({ handleSuccessModal, isShow }) {
    useEffect(() => {
        if (isShow) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isShow]);

    return (
        <MainContainer>
            {isShow ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: isShow && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <TopSection>
                            <CoverText>
                                <CoverImage>
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/tick.svg"
                                        alt="Tick icon"
                                    />
                                </CoverImage>
                                <Text>Enquiry accepted successfully</Text>
                            </CoverText>
                            <ImageContainer
                                onClick={() => {
                                    handleSuccessModal();
                                }}
                            >
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                    alt="Close icon"
                                />
                            </ImageContainer>
                        </TopSection>
                        <TextLen>
                            Enquiry is now assigned for you, let’s get started
                        </TextLen>
                        <Bottom>
                            <Submit onClick={() => handleSuccessModal()}>
                                Continue
                            </Submit>
                        </Bottom>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}
export default EnquiryAcceptModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    right: 0;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 490px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #fff;
    box-shadow: -10px 40px 75px rgba(0, 0, 0, 0.15);
    @media all and (max-width: 1280px) {
        width: 520px;
    }
    @media all and (max-width: 1080px) {
        width: 500px;
    }
    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 480px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const MainContainer = styled.div``;
const Container = styled.div`
    padding: 40px;
    @media all and (max-width: 640px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        padding: 15px;
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    border-bottom: 2px solid #e7e7e7;
`;
const Text = styled.div`
    color: #0a4771;
    font-size: 20px;
    margin-bottom: 20px;
    font-family: "outfit_medium";
    @media all and (max-width: 640px) {
        font-size: 17px;
    }
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    & img {
        display: block;
        width: 100%;
    }
`;
const Submit = styled.div`
    background: #0880e0;
    padding: 10px 25px;
    color: #fff !important;
    :hover {
        color: #fff !important;
    }
    border-radius: 4px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        padding: 8px 20px;
        font-size: 15px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const CoverText = styled.div`
    display: flex;
    align-items: center;
`;
const CoverImage = styled.div`
    width: 40px;
    margin-right: 10px;
    margin-bottom: 20px;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 30px;
    }
`;
const TextLen = styled.div`
    margin-bottom: 50px;
    color: #6b6b6b;
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 30px;
    }
`;
const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;
