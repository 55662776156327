import React, { useState, useContext } from "react";
import styled from "styled-components";
import { supportsConfig } from "../../../AxiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../general/helpers/ButtonLoading";

export default function UpdateCategoryModal({
    isUpdate,
    setUpdate,
    handleUpdateModal,
    categoryPk,
    categoryName,
    setCategoryName,
    refresh,
    setrefresh,
    status,
    setStatus,
    isTender
}) {
    // const [isClick, setClick] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setLoading] = useState(false);

    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    //-----------------------post Update Category list api-------------------------
    const updateHandler = () => {
        setLoading(true);
        if(isTender){
            supportsConfig
                .patch(
                    `api/v1/tenders/admin/project-categories/${categoryPk}/`,
                    {
                        title: categoryName,
                        status: status,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setUpdate(false);
                        setrefresh(!refresh);
                        setLoading(false);
                    } else {
                        setError(data.message);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }else{
            supportsConfig
                .post(
                    `api/v1/enquiries/update-enquiry-category/${categoryPk}/`,
                    {
                        title: categoryName,
                        is_active: status,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setUpdate(false);
                        setrefresh(!refresh);
                        setLoading(false);
                    } else {
                        setError(data.message);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    };
    return (
        <MainContainer>
            {isUpdate ? <Overlay onClick={() => handleUpdateModal()}></Overlay> : ""}
            <BackContainer style={{ transform: isUpdate && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <TopContainer>
                            <CoverText>Update Category</CoverText>
                            <ImageContainer onClick={() => handleUpdateModal()}>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                    alt="Cross"
                                />
                            </ImageContainer>
                        </TopContainer>
                        <Cover>
                            <Label>Name</Label>
                            <TextArea
                                onChange={(e) => {
                                    setCategoryName(e.target.value);
                                    setError("");
                                }}
                                value={categoryName}
                            />
                            {error && <Error>This field may not be blank</Error>}
                        </Cover>
                        <Cover>
                            <Label>Status</Label>

                            <Switch>
                                <Button
                                    className={status ? "active" : ""}
                                    onClick={() => setStatus(!status)}
                                >
                                    <div className={status ? "active" : ""}></div>
                                </Button>
                                <Text>{status ? "Active" : "Inactive"}</Text>
                            </Switch>
                        </Cover>
                        <CoverSubmit>
                            <Cancel onClick={handleUpdateModal}>Cancel</Cancel>
                            <Submit onClick={() => updateHandler()}>
                                {isLoading ? <ButtonLoader /> : "Save"}
                            </Submit>
                        </CoverSubmit>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    background: #fff;
`;
const MainContainer = styled.div``;
const CoverText = styled.h3`
    color: #1a6eb0;
    margin-bottom: 5px;
`;
const Container = styled.div`
    padding: 30px;
    @media all and (max-width: 480px) {
        padding: 30px 20px;
    }
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 25px;
    margin-bottom: 25px;
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    & img {
        display: block;
        width: 100%;
    }
`;
const Cover = styled.div`
    margin-bottom: 20px;
`;
const Label = styled.div`
    color: #929292;
    margin-bottom: 10px;
    font-family: "outfit_light";
    font-size: 16px;
`;
const Text = styled.div`
    color: #707070;
    font-family: "outfit_regular";
`;
const TextArea = styled.input`
    resize: none;
    width: 100%;
    padding: 17px 15px;
    outline: unset;
    background-color: #f5f7fb;
    color: #707070;
    font-size: 16px;
    @media all and (max-width: 480px) {
        padding: 15px 15px;
    }
`;
const Switch = styled.div`
    margin: 15px 0;
    display: flex;
    align-items: center;
`;
const Button = styled.div`
    border: 1px solid #abc1ed;
    background: #f5f7fb;
    height: 23px;
    width: 43px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    transition: 1s ease;
    padding: 4px;
    position: relative;
    &.active {
        border: 1px solid #0880e0;
    }
    & div {
        width: 15px;
        height: 14px;
        background: #abc1ed;
        position: absolute;
        transition: 1s ease;
        border-radius: 50%;
        &.active {
            transition: 1s ease;
            right: 3px;
            transition-duration: 0.4s;
            background: #0880e0;
            border: 1px solid #0880e0;
        }
    }
`;
const CoverSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
    @media all and (max-width: 360px) {
        flex-direction: column;
        align-items: center;
    }
`;
const Cancel = styled.div`
    background: #fff;
    padding: 10px 38px;
    width: 100%;
    height: 45px;
    color: #0880e0;
    border: 1px solid #0880e0;
    margin-right: 15px;
    cursor: pointer;
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 130px;
        height: 40px;
    }
    @media all and (max-width: 360px) {
        margin-bottom: 10px;
        width: 100%;
        height: 45px;
        margin-right: 0px;
    }
`;
const Submit = styled.div`
    background-color: #0880e0;
    color: #fff;
    width: 150px;
    height: 45px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 130px;
        height: 40px;
    }
    @media all and (max-width: 360px) {
        width: 100%;
        height: 45px;
    }
`;
const Error = styled.p`
    color: red;
    font-size: 12px;
    height: 20px;
`;
