import React, { useEffect, useRef, useState } from "react";
// Package
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import styled from "styled-components";
import $ from "jquery";

function ImageCrop({ setImgSrc, setCrop, crop, imgSrc, setFiles, setImage }) {
    const [completedCrop, setCompletedCrop] = useState();
    const blobUrlRef = useRef("");
    const imgRef = useRef(null);

    // cropFunction Cancel
    const cropCancel = () => {
        setImgSrc("");
        setFiles("");
        setImage([]);
    };
    // Outside Scroll
    useEffect(() => {
        if (setImgSrc) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [setImgSrc]);

    //CropedImage
    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(blobUrlRef.current);
                blobUrlRef.current = window.URL.createObjectURL(blob);
                resolve(blob);
            });
        });
    }
    // Croped image Save
    function handleSave() {
        if (completedCrop && imgRef.current) {
            getCroppedImg(imgRef.current, completedCrop, "profile.jpg").then((croppedImage) => {
                setFiles(URL.createObjectURL(croppedImage));
                setImage(croppedImage);
                setImgSrc("");
            });
        }
    }

    return (
        <MainContainer>
            {imgSrc ? <Overlay onClick={() => setImgSrc("")}></Overlay> : ""}
            <BackContainer style={{ transform: imgSrc && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <TopSection>Upload Profile Picture</TopSection>
                        <div className="App">
                            {!!imgSrc && (
                                <Crops
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                >
                                    <Img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{
                                            transform: `scale(${1}) rotate(${0}deg)`,
                                        }}
                                    />
                                </Crops>
                            )}
                        </div>
                        <CoverSubmit>
                            <Cancel onClick={cropCancel}>Cancel</Cancel>
                            <Submit onClick={handleSave}>Crop & Save</Submit>
                        </CoverSubmit>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default ImageCrop;
const MainContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Img = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;

const Crops = styled(ReactCrop)`
    width: auto !important;
    height: 400px;
    margin: 0 auto;
    @media all and (max-width: 480px) {
        height: 350px;
    }
    @media all and (max-width: 360px) {
        height: 300px;
    }
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    background: #fff;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow-y: hidden;
    @media all and (max-width: 640px) {
        max-width: 500px;
    }
`;
const Container = styled.div`
    padding: 30px;
    div {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

const TopSection = styled.h3`
    color: #0a0a0a;
    font-size: 18px;
    font-family: "outfit_medium";
    margin-bottom: 20px !important;
`;

const Cancel = styled.button`
    background: #fff;
    height: 45px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0880e0;
    border: 1px solid #0880e0;
    margin-right: 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: "outfit_medium";
`;
const Submit = styled.button`
    background: #0880e0;
    height: 45px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 16px;
    font-family: "outfit_medium";
`;
const CoverSubmit = styled.div`
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    margin-top: 30px;
    @media all and (max-width: 480px) {
        justify-content: center !important;
    }
`;
