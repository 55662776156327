import React, { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../contexts/Store";

const AuthRoute = ({ children }) => {
    const {
        state: { user_data },
    } = useContext(Context);
    const isLogin = user_data.isLogin;

    const [searchParams] = useSearchParams();

    const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

    return !isLogin ? children : <Navigate to={nextPath} />;
};

export default AuthRoute;
