import styled from "styled-components";

export default function SectionLoader() {
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: loaderAnimation,
    // };
    return (
        <Container>
            {/* <Lottie
                options={defaultOptions}
                height={220}
                width={220}
                // ref={containerRef}
            /> */}
            <Span></Span>
        </Container>
    );
}
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const Span = styled.span`
    width: 88px;
    height: 88px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 5px solid;
    border-color: #0880e0 #0880e0 transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    ::before,
    ::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid;
        border-color: transparent transparent #0880e0 #0880e0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes rotationBack {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
`;
