import React, { Suspense } from "react";
import "../../../assets/css/style.css";

// packages
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

// components
import Header from "../../ui/general/includes/Header";
import SideBar from "../../ui/general/includes/Sidebar";
import SectionLoader from "../../ui/general/helpers/SectionLoader";
import AdminRoute from "../routes/AdminRoute";
import AdminRouter from "./roles/AdminRouter";
import RmRoute from "../routes/RmRoute";
import RmRouter from "./roles/RmRouter";

function AppRouter() {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Main>
                <Header />
                <SideBar />
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <AdminRoute>
                                <AdminRouter />
                            </AdminRoute>
                        }
                    />
                    <Route
                        path="/rm/*"
                        element={
                            <RmRoute>
                                <RmRouter />
                            </RmRoute>
                        }
                    />
                </Routes>
            </Main>
        </Suspense>
    );
}

export default AppRouter;
const Main = styled.div``;
