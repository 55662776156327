import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Packages
import styled from "styled-components";
import { supportsConfig } from "../../../../../AxiosConfig";
import { Context } from "../../../../contexts/Store";
import SectionLoader from "../../../general/helpers/SectionLoader";

function ProjectAssets({ 
    refresh, 
    setBox,
    isDelete,
    handleDeleteModal,
    setTender,
    setAssets,
    setSelectedAsset,
 }) {
    const [projectAssets, setProjectAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState();
    const [isActive, setActive] = useState("");
    const { id } = useParams();
    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    const handleProjectsData = () => {
        setLoading(true);
        supportsConfig
            .get(`api/v1/tenders/admin/projects/${id}/project-assets/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    filter_status: isActive,
                    q: searchInput,
                    page: currentPage,
                    pagination: "pagination",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPagination(data.pagination_data);
                    setProjectAssets(data.data);
                    setLoading(false);
                } else {
                    if (StatusCode === 6001) {
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //------------------------get Category list api--------------------------------------
    useEffect(() => {
        handleProjectsData();
    }, [isActive, searchInput, currentPage, refresh, access_token]);

    return (
        <ProjectDetailContainer>
            <FieldContainer className="heading">
                <Heading>Assets</Heading>
                <AddNew onClick={() => setBox(true)}>
                    <span>Add New</span>
                    <FilterIcon>
                        <img
                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FAdd-New-icon.svg"
                            alt="filter"
                        />
                    </FilterIcon>
                </AddNew>
            </FieldContainer>
            {
                loading ?
                <LoaderContainer>
                    <SectionLoader />
                </LoaderContainer>
                :
                projectAssets?.map((asset, index) => (
                    <FieldContainer>
                        <Label>{index+1}. {asset?.name}</Label>
                        <CoverScroll>
                            <Items>
                                {/* {files && ( */}
                                    <Item href={asset.file} target="_blank"  id="file">
                                        <ImgContainer>
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/fileupload.svg"
                                                alt="Attachments"
                                            />
                                        </ImgContainer>
                                        <TextName>{asset?.file.split('project_assets/')[1]}</TextName>
                                        {/* <ImgContainer
                                            // onClick={() => {
                                            //     deleteFile();
                                            // }}
                                        >
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/closeicon.svg"
                                                alt="Close"
                                            />
                                        </ImgContainer> */}
                                    </Item>
                                {/* )} */}
                            </Items>
                        </CoverScroll>
                        <ButtonContainer>
                            <DeleteButton type="delete" onClick={() => {
                                setSelectedAsset(asset.id)
                                setAssets(true);
                                setTender(false);
                                handleDeleteModal()
                                }}>
                                <span>Delete</span>
                            </DeleteButton>
                        </ButtonContainer>
                    </FieldContainer>
                ))
            }
        </ProjectDetailContainer>
    );
}

export default ProjectAssets;

const ProjectDetailContainer = styled.div`
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
`;
const Heading = styled.h3`
    font-size: 24px;
    font-weight: bold;
    margin: 0 10px 10px;
    border-bottom: 1px solid #ccc;
    width: fit-content;
`;
const AddNew = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    /* background-color: #0880e0; */
    background-color: ${({ type }) => (type === "delete" ? "#f23b3b" : "#0880e0")};
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #fff;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const FilterIcon = styled.div`
    width: 20px;

    img {
        width: 100%;
        display: block;
    }
`;
const LoaderContainer = styled.div`
    min-height: 300px;
    position: relative;
`
const FieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 50%;
    width: 100%;
`;
const Label = styled.div`
    font-size: 18px;
    width: 20%;
`;
const CoverScroll = styled.div`
    /* overflow-x: scroll; */
    width: 30%;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 480px) {
        width: 50%;
    }
`;

const Items = styled.div`
    /* min-width: 700px; */
    display: flex;
`;
const Item = styled.a`
    display: flex;
    overflow: hidden;
    margin-right: 5px;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 6px 12px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #0884df;
    border-radius: 4px;
`;
const TextName = styled.div`
    color: #707070;
    font-family: "outfit_light";
    font-size: 14px;
    /* width: 100px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const ButtonContainer = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const DeleteButton = styled.div`
    padding: 10px 15px;
    width: fit-content;
    background-color: #f23b3b;
    border-radius: 5px;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #fff;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const ImgContainer = styled.div`
    /* margin-right: 5px; */
    width: 20px;
    :last-child {
        margin-left: 5px;
        margin-right: 0;
        cursor: pointer;
    }
    img {
        width: 100%;
        display: block;
    }
`;