import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
// Imports
import SectionLoader from "../../../general/helpers/SectionLoader";
import { accountsConfig } from "../../../../../AxiosConfig";
import RmProfileList from "../includes/RmProfileList";
import { Context } from "../../../../contexts/Store";
import styled from "styled-components";

//Package
import "react-image-crop/dist/ReactCrop.css";
import ImageCrop from "../modal/ImageCropModal";
import WebcameraModal from "../modal/WebcameraModal";
import ViewPhotoModal from "../modal/ViewPhotoModal";
import RemoveProfileModal from "../modal/RemoveProfileModal";
import ButtonLoading from "../../../general/helpers/ButtonLoading";

function ProfilePage() {
    const [capturedImage, setCapturedImage] = useState(null);
    const [viewPhoto, setViewPhoto] = useState(false);
    const [isCaptured, setCaptured] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [preview, setPreview] = useState(false);
    const [update, setUpdate] = useState(false);
    const [webcam, setWebcam] = useState(false);
    const [remove, setRemove] = useState(false);
    const [isLoad, setLoad] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [list, setList] = useState(false);
    const [files, setFiles] = useState("");
    const [image, setImage] = useState([]);
    const [upload, setupload] = useState("");
    const [data, setData] = useState([]);
    const [name, setName] = useState("");
    const lines = data?.name?.split(" ");
    const [crop, setCrop] = useState();
    const wrapperRef = useRef(null);
    const webcamRef = useRef(null);
    const listRef = useRef(null);

    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCaptured(true);
        setCapturedImage(imageSrc);
    };

    // Close Camera
    const CameraClose = () => {
        setWebcam(false);
        setCapturedImage(null);
        setCaptured(false);
        setImage(null);
    };
    // Save Photo
    const handleSave = () => {
        setWebcam(false);
        setCaptured(false);
        setFiles(capturedImage);

        // Convert the Base64-encoded image data to a Blob
        const byteString = atob(capturedImage.split(",")[1]);
        const mimeString = capturedImage.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        setImage(blob);
    };

    // outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setViewPhoto(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    // update profile
    const uploadFileHandler = (event) => {
        setFiles(URL.createObjectURL(event.target.files[0]));
        setImage(event.target.files[0]);
    };

    //------------------ upload profile api ---------------------------
    const handlePRofileUpdate = () => {
        setLoad(true);
        const formData = new FormData();
        formData.append(`name`, name);
        if (image?.length !== 0) {
            formData.append(`photo`, image);
        }
        if (upload === "True") {
            formData.append(`delete_photo`, upload);
        }
        name &&
            accountsConfig
                .post(`api/v1/users/rm-update-profile/`, formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setUpdate(false);
                        setRefresh(!refresh);
                        setFiles("");
                        setLoad(false);
                        setRemove(false);
                        setupload("");
                    } else {
                        setUpdate(false);
                        setRefresh(!refresh);
                        setFiles("");
                        setLoad(false);
                        setupload("");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setRefresh(!refresh);
                    setLoad(false);
                    setupload("");
                });
    };

    //--------------------------------------------get---profile api----------------------------------------

    useEffect(() => {
        setLoading(true);
        accountsConfig
            .get(`api/v1/users/rm-profile/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data.data);
                    setLoading(false);
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            rm_name: data.data.name,
                        },
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access_token, refresh]);

    return (
        <Container id="main">
            {isLoading ? (
                <SectionLoader />
            ) : (
                <Fragment>
                    <Heading>My Profile</Heading>

                    <ProfileContainer>
                        <ThumbnailBar></ThumbnailBar>
                        <TopBar>
                            <CoverPic>
                                <CoverPicture>
                                    <PhotoContainer
                                        style={{
                                            backgroundImage: files
                                                ? `url(${files})`
                                                : `url(${data.photo})`,
                                        }}
                                    >
                                        {!data.photo && !files && (
                                            <Letter>
                                                {lines && lines[0][0]}
                                                {lines?.length > 1 && lines[1][0]}
                                            </Letter>
                                        )}

                                        <Input
                                            type="File"
                                            accept="image/*"
                                            id="upload"
                                            style={{ display: "none" }}
                                            onChange={(e) => uploadFileHandler(e)}
                                        />
                                        <Cover></Cover>
                                    </PhotoContainer>
                                    {update && (
                                        <Camera
                                            ref={listRef}
                                            onClick={() => {
                                                setList(!list);
                                                setImage([]);
                                            }}
                                        >
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FCameraWhite.svg"
                                                alt="camera"
                                            />
                                        </Camera>
                                    )}
                                    {list && (
                                        <RmProfileList
                                            setPreview={setPreview}
                                            setWebcam={setWebcam}
                                            setImgSrc={setImgSrc}
                                            setRemove={setRemove}
                                            setImage={setImage}
                                            setupload={setupload}
                                            setCrop={setCrop}
                                            listRef={listRef}
                                            setList={setList}
                                            data={data}
                                        />
                                    )}
                                </CoverPicture>
                            </CoverPic>

                            <CoverProfile>
                                <ProfileName>
                                    <Name>{data.name}</Name>
                                    <Email>{data.username}</Email>
                                </ProfileName>
                                <EditButton
                                    onClick={() => {
                                        setUpdate(true);
                                        setErrorMsg(false);
                                        setName(data.name);
                                    }}
                                >
                                    <IconContainer>
                                        <img
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/carbon_edit.svg"
                                            alt="edit"
                                        />
                                    </IconContainer>
                                    <span>Update profile</span>
                                </EditButton>
                            </CoverProfile>
                        </TopBar>
                        <BottomSection>
                            <Items>
                                <Title>Name</Title>
                                <Seperator>:</Seperator>
                                {update ? (
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                ) : (
                                    <TitleValue>{data?.name}</TitleValue>
                                )}
                            </Items>

                            <Items>
                                <Title>Username</Title>
                                <Seperator>:</Seperator>

                                <TitleValue>{data.username}</TitleValue>
                            </Items>

                            <Items className="phone">
                                <Title>Email</Title>
                                <Seperator>:</Seperator>

                                <TitleValue>
                                    <span>{data.email}</span>
                                </TitleValue>
                            </Items>
                            <Items className="phone">
                                <Title>Phone no</Title>
                                <Seperator>:</Seperator>

                                <TitleValue>
                                    <span>{data.phone}</span>
                                </TitleValue>
                            </Items>
                        </BottomSection>
                        {update && (
                            <EditTab>
                                <DiscardButton
                                    onClick={() => {
                                        errorMsg === false && setUpdate(false);
                                        setFiles("");
                                    }}
                                >
                                    <span>Discard</span>
                                </DiscardButton>
                                <SaveButton
                                    encType="multipart/form-data"
                                    onClick={handlePRofileUpdate}
                                >
                                    <span>{isLoad ? <ButtonLoading /> : "Save"}</span>
                                </SaveButton>
                            </EditTab>
                        )}
                    </ProfileContainer>

                    {viewPhoto && (
                        <ViewPhoto
                            ref={wrapperRef}
                            style={{
                                transform: viewPhoto && "scale(1,1)",
                                backgroundImage: `url(${data.photo})`,
                            }}
                        ></ViewPhoto>
                    )}

                    <ImageCrop
                        crop={crop}
                        imgSrc={imgSrc}
                        setCrop={setCrop}
                        setFiles={setFiles}
                        setImage={setImage}
                        setImgSrc={setImgSrc}
                    />
                    <WebcameraModal
                        webcam={webcam}
                        capture={capture}
                        webcamRef={webcamRef}
                        isCaptured={isCaptured}
                        handleSave={handleSave}
                        setCaptured={setCaptured}
                        CameraClose={CameraClose}
                        capturedImage={capturedImage}
                    />
                    <ViewPhotoModal preview={preview} setPreview={setPreview} data={data} />
                    <RemoveProfileModal
                        handlePRofileUpdate={handlePRofileUpdate}
                        setRemove={setRemove}
                        setupload={setupload}
                        remove={remove}
                        isLoad={isLoad}
                    />
                </Fragment>
            )}
        </Container>
    );
}

export default ProfilePage;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;
    overflow-y: scroll;
`;

const Camera = styled.div`
    position: absolute;
    background-color: #0880e0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    right: 0;
    top: 70%;
    img {
        width: 25px;
    }
`;
const Cover = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 50%;
    opacity: 0.1;
    display: none;
    z-index: 10;
`;
const Contents = styled.p`
    font-size: 14px;
    display: inline-block;
    animation: scale-display 0.3s;
    display: inline-flex;
    z-index: 20;
    position: absolute;
    color: black;

    display: none;
`;
const Heading = styled.h4`
    margin-bottom: 0 !important;
    padding: 10px 0px;
    color: #0a0a0a;
    font-size: 18px;
    font-family: "outfit_medium";
`;
const PhotoContainer = styled.div`
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    vertical-align: middle;
    text-align: center;
    color: transparent;
    transition: all 0.3s ease;
    text-decoration: none;
    background: #deeefb;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.profile {
        cursor: pointer;
        ${Contents} {
            display: block;
        }
        ${Cover} {
            display: block;
        }
    }
`;
const Letter = styled.h3`
    font-size: 40px;
    font-family: "outfit_bold";
    text-transform: uppercase;

    color: #0880e0;
`;
const Input = styled.input`
    position: absolute;
    top: 59px;
    left: 25px;
    width: 120px;
    opacity: 0;
    cursor: pointer;
`;
const BottomSection = styled.div`
    margin-top: 30px;
    padding: 20px 65px;
    @media all and (max-width: 768px) {
        overflow-x: scroll;
        padding: 20px 0px;
    }
`;
const Items = styled.li`
    position: relative;
    display: flex;
    padding: 13px 25px;
    margin-bottom: 10px;
    color: #adb7c0;
    @media all and (max-width: 640px) {
        min-width: 640px;
    }

    &:nth-child(even) {
        background: #f9fbfd;
    }
    :nth-child(2) {
        position: relative;
    }
    input {
        color: #adb7c0;
        outline: none;
        border: 1px solid #0880e0;
        border-radius: 3px;
        padding: 7px 10px;
        width: 50%;
        font-size: 16px;
        position: absolute;
        right: 0;
    }
`;

const Title = styled.span`
    display: inline-block;
    width: 30%;
    font-size: 16px;
    color: #858585;
`;
const TitleValue = styled.span`
    display: inline-block;
    width: 50%;
    font-size: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.phone-number {
        display: inline-flex;
        justify-content: space-between;
        border: 1px solid #0880e0;
        border-radius: 3px;
        padding: 0 10px;
    }
    input:focus-visible {
        border: none !important;
    }
    input.country,
    span {
        width: 28px;
    }
    input.phone {
        padding: 0px;
        width: 92%;
    }
`;
const ProfileContainer = styled.div`
    background: #fff;
    width: 100%;
    padding: 40px 120px;
    margin-bottom: 20px;
    /* min-height: calc(100vh - 150px); */
    @media all and (max-width: 1280px) {
        padding: 40px 40px;
    }
    @media all and (max-width: 640px) {
        padding: 40px 0px;
    }
`;
const ThumbnailBar = styled.div`
    height: 200px;
    margin-top: 25px;
    background-size: cover;
    margin: 15px 15px 0 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/profilethumbnail.svg");
    @media all and (max-width: 488px) {
        display: none;
    }
`;
const TopBar = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    @media all and (max-width: 488px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
const CoverProfile = styled.div`
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    @media all and (max-width: 1350px) {
        width: 83%;
    }
    @media all and (max-width: 1280px) {
        width: 85%;
    }
    @media all and (max-width: 1200px) {
        width: 82%;
    }
    @media all and (max-width: 1110px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        width: 76%;
    }
    @media all and (max-width: 800px) {
        width: 73%;
    }
    @media all and (max-width: 768px) {
        justify-content: flex-end;
    }

    @media all and (max-width: 488px) {
        justify-content: center;
        width: 100%;
    }
`;
const CoverPic = styled.div`
    position: absolute;
    top: -80px;
    left: 45px;
    @media all and (max-width: 488px) {
        position: unset;
    }
`;
const CoverPicture = styled.div`
    position: relative;
`;
const ProfileName = styled.div`
    cursor: unset;

    @media all and (max-width: 768px) {
        display: none;
    }
`;
const Name = styled.div`
    color: #087ee1;
    font-size: 26px;
    font-family: "outfit_medium";
    cursor: unset;
`;
const Email = styled.div`
    color: #0a0a0a;
    font-size: 16px;
`;
const EditButton = styled.div`
    display: flex;
    background: #0880e0;
    padding: 10px 10px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;

    span {
        font-size: 16px;
    }
`;
const IconContainer = styled.div`
    margin-right: 8px;

    img {
        width: 100%;
        display: block;
    }
`;
const Seperator = styled.div`
    width: 20%;
`;

const EditTab = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    @media all and (max-width: 480px) {
        justify-content: center;
        margin-top: 30px;
    }
`;
const DiscardButton = styled.div`
    color: #0880e0;
    border: 1px solid #0880e0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 45px;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
    border-radius: 3px;
`;
const SaveButton = styled.div`
    border-radius: 3px;
    background: #0880e0;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 45px;
    text-align: center;
    color: #fff;
`;
const ViewPhoto = styled.div`
    width: 400px;
    height: 400px;
    transform: scale(0, 0) !important;
    position: absolute;
    top: 50%;
    border-radius: 5px;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 4px 50px rgb(0 0 0 / 8%);
    z-index: 20;
`;
