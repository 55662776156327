import React, { useEffect } from "react";
import styled from "styled-components";
import "react-image-crop/dist/ReactCrop.css";
import Webcam from "react-webcam";
import $ from "jquery";

function WebcameraModal({
    capturedImage,
    CameraClose,
    setCaptured,
    handleSave,
    isCaptured,
    webcamRef,
    capture,
    webcam,
}) {
    // Outside Scroll
    useEffect(() => {
        if (webcam) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [webcam]);
    return (
        <MainContainer>
            {webcam ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: webcam && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <CloseContainer>
                            <CloseImage onClick={CameraClose}>
                                <img
                                    className="close"
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                    alt="close"
                                />
                            </CloseImage>
                        </CloseContainer>
                        <WebCamera>
                            {isCaptured ? (
                                <img src={capturedImage} alt="capture" />
                            ) : (
                                webcam && <Web audio={false} ref={webcamRef} />
                            )}
                            <ClickContainer>
                                {isCaptured ? (
                                    <>
                                        <Cameras onClick={() => setCaptured(false)}>
                                            <img
                                                className="close"
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                                alt="close"
                                            />
                                        </Cameras>
                                        <CameraSave onClick={handleSave}>Save</CameraSave>
                                    </>
                                ) : (
                                    <Cameras onClick={capture}>
                                        <img
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FCameraWhite.svg"
                                            alt="camera"
                                        />
                                    </Cameras>
                                )}
                            </ClickContainer>
                        </WebCamera>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default WebcameraModal;
const CloseContainer = styled.div`
    background-color: #fff !important;
    padding: 20px 20px;
    display: flex;
    justify-content: flex-end;
`;
const CloseImage = styled.div`
    cursor: pointer;
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const CameraSave = styled.button`
    background: #0880e0;
    padding: 10px 40px;
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
`;

const Cameras = styled.div`
    background-color: #0880e0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    :nth-child(1) {
        margin-left: 0;
    }
    img {
        width: 25px !important;
        &.close {
            width: 15px !important;
        }
    }
`;
const ClickContainer = styled.div`
    display: flex;
    margin-top: 10px;
`;

const WebCamera = styled.div`
    overflow: hidden;
    z-index: 30;
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0px 20px;
    img {
    }
`;
const Web = styled(Webcam)`
    width: 100%;
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    right: 0;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #fff;
    box-shadow: -10px 40px 75px rgba(0, 0, 0, 0.15);

    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const MainContainer = styled.div``;
const Container = styled.div`
    padding: 10px 0px;
`;
