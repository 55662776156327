import React from "react";
import styled from "styled-components";

function NodataFound() {
    return (
        <Container>
            <Image>
                <img
                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FnodataFoundNew.svg"
                    alt="no_data"
                />
            </Image>
            <Content>No Data Found</Content>
        </Container>
    );
}

export default NodataFound;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const Image = styled.div`
    width: 250px;

    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        width: 200px;
    }
    @media all and (max-width: 360px) {
        width: 180px;
    }
`;
const Content = styled.div`
    color: #0a0a0a;
    font-size: 20px;
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 15px;
    }
`;
