import React, { useContext, useState } from "react";

//Packages
import styled from "styled-components";

//Components
import { Context } from "../../../contexts/Store";
import { accountsConfig } from "../../../../AxiosConfig";
import ButtonLoading from "../../general/helpers/ButtonLoading";
import ForgotPassword from "./ForgotPassword";

function Login() {
    const { dispatch } = useContext(Context);
    const [isLoading, setLoading] = useState(false);
    const [hide, setHide] = useState(true);
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [error, setError] = useState(false);
    const [warning, setWarning] = useState("");
    const [change, setChange] = useState("admin");
    const [forgot, setForgot] = useState(false);

    //-----------------hide password-----------------------------------------------
    const handlePasswordShow = () => {
        setHide(!hide);
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            submitHandler(e);
        } else if (e.keyCode === 69) {
            e.preventDefault();
        }
    };

    //----------------------------login api---------------------------------------
    const submitHandler = () => {
        if (emailValue && passwordValue) {
            setError(false);
            setLoading(true);
            accountsConfig
                .post("api/v1/users/login/executive-profile/", {
                    service_name: "supports",
                    username: emailValue,
                    password: passwordValue,
                    profile_type: change,
                })
                .then((response) => {
                    console.log(response, "response from login inside 6000")
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        console.log("response from login inside 6000")
                        setLoading(false);
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                isLogin: true,
                                access_token: data.oauth_credentials.access_token,
                                email: data.email,
                                // password: passwordValue,
                                user_id: data.username,
                                role: data.profile_type,
                                rm_name: data.name,
                            },
                        });
                    } else {
                        setLoading(false);
                        setWarning(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error, "error from login inside 6001")

                    setLoading(false);
                });
        } else {
            setError(true);
        }
    };
    //----------------------------------------------------------------------------
    return (
        <Container>
            <Left>
                <LeftContainer>
                    <Logos>
                        <ImageContainer>
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/log-logo.svg"
                                alt="Logo"
                            />
                        </ImageContainer>
                    </Logos>
                    <Text>Your trust is our Responsibility</Text>
                    <Description>
                        Stikcon founded by Top-notch technical and business acumen with a track
                        record in AEC (Architectural Engineering and Construction) Industry.
                        <br /> <br />
                        Our team includes Architects, Designers, Civil Engineers, MEP Engineers, and
                        Data Scientists who are joining forces to re-invent modern architecture
                        Design, Construction project management and we increase the efficiency of
                        construction projects through modern, user-friendly solutions.
                    </Description>
                </LeftContainer>
                <SpotlightImg>
                    <img
                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/Spotlight.png"
                        alt="spotlight_image"
                    />
                </SpotlightImg>
            </Left>
            <Right>
                {forgot ? (
                    <ForgotPassword setForgot={setForgot} emailValue={emailValue} change={change} />
                ) : (
                    <RightContainer>
                        <RightHead>
                            <h2>Welcome Back!</h2>
                            <p>Please login to your account</p>
                        </RightHead>

                        <Switch>
                            <SwitchProfile
                                onClick={() => setChange("admin")}
                                className={change === "admin" ? "change" : ""}
                            >
                                {change === "admin" ? (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Frm-login-blue.svg"
                                        alt="icon"
                                    />
                                ) : (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Frm-login-gray.svg"
                                        alt="icon"
                                    />
                                )}
                                <p className={change === "admin" ? "change" : ""}>Admin</p>
                            </SwitchProfile>
                            <SwitchProfile
                                onClick={() => setChange("relationship_manager")}
                                className={change === "relationship_manager" ? "change" : ""}
                            >
                                {change === "relationship_manager" ? (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Frm-login-blue.svg"
                                        alt="icon"
                                    />
                                ) : (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Frm-login-gray.svg"
                                        alt="icon"
                                    />
                                )}
                                <p className={change === "relationship_manager" ? "change" : ""}>
                                    Rm
                                </p>
                            </SwitchProfile>
                        </Switch>
                        <Form>
                            <Cover>
                                <EmailText>User name</EmailText>
                                <EmailCover>
                                    <InputField
                                        type="text"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        onChange={(e) => {
                                            setError(false);
                                            setWarning("");
                                            setEmailValue((e.target.value = e.target.value.trim()));
                                        }}
                                    />
                                </EmailCover>
                                {warning === "Invalid username" && (
                                    <ErrorMessage>{warning} </ErrorMessage>
                                )}
                                {error && !emailValue && (
                                    <ErrorMessage>This field is required</ErrorMessage>
                                )}
                            </Cover>
                            <Cover>
                                <EmailText>Password</EmailText>
                                <EmailCover>
                                    <InputField
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        type={hide ? "password" : "text"}
                                        onChange={(e) => {
                                            setError(false);
                                            setWarning("");
                                            setPasswordValue(
                                                (e.target.value = e.target.value.trim())
                                            );
                                        }}
                                    />
                                </EmailCover>

                                <EyeCover
                                    onClick={handlePasswordShow}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img
                                        src={
                                            hide
                                                ? "https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/hide-eye.png"
                                                : "https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/eye-hide.svg"
                                        }
                                        alt="Logo"
                                    />
                                </EyeCover>
                                {warning === "Invalid password" && (
                                    <ErrorMessage>{warning} </ErrorMessage>
                                )}
                                {error && !passwordValue && (
                                    <ErrorMessage>This field is required</ErrorMessage>
                                )}
                                {warning === "Incorrect profile type" && (
                                    <ErrorMessage>{warning} </ErrorMessage>
                                )}
                            </Cover>
                            {change === "admin" && (
                                <Forgot onClick={() => setForgot(true)}>Forget password?</Forgot>
                            )}

                            <Button
                                onClick={() => {
                                    submitHandler();
                                }}
                            >
                                {isLoading ? <ButtonLoading /> : <span>Login</span>}{" "}
                            </Button>
                        </Form>
                    </RightContainer>
                )}
            </Right>
        </Container>
    );
}

export default Login;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #fff;
    @media all and (max-width: 768px) {
        flex-direction: column;
    }

    @media all and (max-width: 640px) {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    @media all and (max-width: 1280px) {
        padding: 30px 75px;
    }
    @media all and (max-width: 1080px) {
        padding: 20px 25px;
    }
    @media all and (max-width: 768px) {
        padding: 10px 15px;
    }
`;
const EyeCover = styled.div`
    position: absolute;
    right: 15px;
    bottom: 8px;
    img {
        width: 20px;
        height: 25px;
        filter: contrast(0.1);
    }
    @media all and (max-width: 480px) {
        bottom: 3px;
    }
`;
const Logos = styled.div`
    width: 110px;
    margin-bottom: 20px;
    @media all and (max-width: 1080px) {
        width: 95px;
    }
    @media (max-width: 480px) {
        width: 75px;
    }
    @media (max-width: 480px) {
        width: 65px;
    }
`;
const ImageContainer = styled.div`
    width: 100%;
    img {
        width: 100%;
        display: block;
    }
`;
const Text = styled.div`
    color: #f0f0f0;
    margin-bottom: 15px;
    font-size: 26px;
    @media all and (max-width: 1080px) {
        font-size: 23px;
    }
    @media all and (max-width: 768px) {
        font-size: 20px;
    }
    @media (max-width: 480px) {
        font-size: 17px;
    }
`;
const Description = styled.div`
    color: #eaeaea;
    font-size: 15px;
    width: 90%;
    opacity: 0.8;
    position: relative;
    @media (max-width: 480px) {
        font-size: 14px;
    }
    &::after {
        content: "";
        background: #fff;
        opacity: 1;
        width: 190px;
        position: absolute;
        height: 2px;
        bottom: -56px;
        left: 0;
        @media all and (max-width: 1280px) {
            bottom: -35px;
        }
        @media all and (max-width: 1280px) {
            bottom: -20px;
        }
        @media (max-width: 480px) {
            display: none;
        }
    }
`;

const EmailCover = styled.div`
    height: 50px;
    border: 1px solid #e0e0e0;
    opacity: 0.5;
    width: 100%;
    @media all and (max-width: 480px) {
        height: 40px;
    }
`;
const Form = styled.form`
    width: 100%;
`;
const Cover = styled.div`
    margin-bottom: 20px;
    position: relative;
    &:nth-child(2) {
        margin-bottom: 0;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 15px;
    }
`;
const EmailText = styled.div`
    color: #929292;
    margin-bottom: 5px;
    font-family: "outfit_light";
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;
const InputField = styled.input`
    width: 100%;
    height: 100%;
    font-size: 16px;
    background: #fff !important;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    outline-color: #0883e0;
`;
const Button = styled.div`
    background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;

    @media all and (max-width: 480px) {
        height: 40px;
    }
`;
const Left = styled.div`
    width: 50%;
    background: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/Rectangle%20.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @media all and (max-width: 768px) {
        min-height: 50vh;
        width: 100%;
    }
`;
const ErrorMessage = styled.p`
    color: red;
    font-size: 13px;
    height: 30px;
    position: absolute;
    top: 100%;
    display: inline;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const Right = styled.div`
    width: 50%;
    padding: 60px;
    display: flex;
    justify-content: center;
    @media all and (max-width: 980px) {
        width: 50%;
        padding: 30px;
    }
    @media all and (max-width: 768px) {
        padding: 15px;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        padding: 30px;
    }
    @media all and (max-width: 360px) {
        padding: 30px 10px;
    }
`;
const SpotlightImg = styled.div`
    width: 87%;
    text-align: right;
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        width: 50%;
    }
    @media all and (max-width: 640px) {
        display: none;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const RightContainer = styled.div`
    width: 80%;
    @media all and (max-width: 980px) {
        width: 95%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const Switch = styled.div`
    background-color: #f5f5f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    border-radius: 6px;
    margin-bottom: 20px;
`;
const SwitchProfile = styled.div`
    display: flex;
    transition: 0.4s ease;

    padding: 7px 0px;
    width: 44%;

    border-radius: 6px;
    justify-content: center;
    cursor: pointer;
    img {
        width: 20px;
    }
    p {
        margin-left: 5px;
        font-size: 16px;
        color: #929292;

        &.change {
            color: #0880e0;
        }
    }
    &.change {
        background-color: #fff;
        box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.08);
    }
`;
const RightHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    h2 {
        font-family: "outfit_medium";
        color: #0a0a0a;
    }
    p {
        color: #747474;
        font-size: 15px;
        margin-top: 3px;
    }
`;
const Forgot = styled.span`
    color: #858585;
    font-size: 14px;
    font-family: "outfit_light";
    text-align: right;
    display: inherit;
    margin-top: 3px;
    cursor: pointer;
`;
