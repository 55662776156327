import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

// packages
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import LogOutModal from "../../screens/Rm/modal/LogOutModal";

function Header() {
    const {
        dispatch,
        state: { user_data, aside_status },
    } = useContext(Context);

    const name = user_data.rm_name;

    const role = user_data.role;

    const sidebar_status = aside_status.sidebar_status;
    const [dropDown, setDropDown] = useState(false);
    const [status, setStatus] = useState("");
    const firstPath = window.location.pathname.split("/")[3];
    const [isLogout, setLogout] = useState(false);

    const headerRef = useRef(null);
    const wrapperRef = useRef(null);

    const handleStatus = (value) => {
        dispatch({
            type: "UPDATE_STATUS",
            aside_status: {
                sidebar_status: value,
            },
        });
    };
    //----- Profile Dropdown----

    const handleProfile = () => {
        if (name !== "Admin") {
            setDropDown(!dropDown);
        }
    };

    //----------------outside click------------------------
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (headerRef.current && headerRef.current.contains(event.target)) {
                    } else {
                        setDropDown(false);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    return (
        <Fragment>
            <Main>
                <Container>
                    <Left>
                        <ImageContainer to="/">
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/stickon-logo.svg"
                                alt="Logo"
                            />
                        </ImageContainer>
                        <Hamburger
                            onClick={() =>
                                sidebar_status ? handleStatus(false) : handleStatus(true)
                            }
                        >
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FHamburger_Menu.svg"
                                alt="hamburger"
                            />
                        </Hamburger>
                    </Left>

                    <RighEnd
                        className={role === "relationship_manager" ? "Rm" : ""}
                        ref={headerRef}
                        onClick={handleProfile}
                    >
                        <Letter className={dropDown ? "drop" : ""}></Letter>
                        <Text>
                            <h2>{name}</h2>
                        </Text>
                    </RighEnd>
                </Container>
                {dropDown && (
                    <DropDown ref={wrapperRef}>
                        <Profile
                            to="enquiry/profile"
                            className={firstPath === "profile" ? "status" : ""}
                            onClick={() => {
                                setStatus("profile");
                                setDropDown(false);
                            }}
                        >
                            <Icon>
                                {firstPath === "profile" ? (
                                    <img
                                        src="https://stikcon-payments-static-and-media.sgp1.cdn.digitaloceanspaces.com/media/payment_receipt/enquiries.svg"
                                        alt="profile"
                                    />
                                ) : (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/profile3.svg"
                                        alt="Icon"
                                    />
                                )}
                            </Icon>
                            <span className={firstPath === "profile" ? "status" : ""}>
                                View Profile
                            </span>
                        </Profile>
                        <Profiles
                            onClick={(e) => {
                                setDropDown(false);
                                setStatus("");
                                setLogout(true);
                            }}
                        >
                            <Icon>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/logout-blue.svg"
                                    alt="logout"
                                />
                            </Icon>
                            <span className={status === "logout" ? "status" : ""}>Log out</span>
                        </Profiles>
                    </DropDown>
                )}

                <Outlet />
            </Main>
            <LogOutModal setLogout={setLogout} isLogout={isLogout} />
        </Fragment>
    );
}

export default Header;

const DropDown = styled.div`
    background-color: #fff;
    z-index: 30;
    position: absolute;
    right: 15px;
    top: 85px;
    padding: 20px 20px;
    border-bottom: 1px solid #0984df;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
`;
const Profile = styled(Link)`
    display: flex;
    margin-top: 10px;
    width: 200px;
    cursor: pointer;
    padding: 10px;
    :nth-child(1) {
        margin-top: 0;
    }

    span {
        color: #939393;
        font-size: 16px;
        &.status {
            color: #fff;
        }
    }
    &.status {
        background-color: #0a84df;
    }
`;
const Profiles = styled.div`
    display: flex;
    margin-top: 10px;
    width: 200px;
    cursor: pointer;
    padding: 10px;
    :nth-child(1) {
        margin-top: 0;
    }

    span {
        color: #939393;
        font-size: 16px;
        &.status {
            color: #fff;
        }
    }
    &.status {
        background-color: #0a84df;
    }
`;
const Icon = styled.div`
    width: 20px;
    margin-right: 10px;
    img {
        width: 100%;
        display: block;
        height: 100%;
    }
`;

const Main = styled.div``;

const Text = styled.div`
    color: #858585;
    margin-left: 5px;
    text-transform: capitalize;

    h2 {
        color: #949494;
        font-family: "outfit_medium";
        font-size: 17px !important;
    }
`;
const Letter = styled.div`
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 50%;
    position: relative;
    background: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FAdmin-icon-new.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    &.drop {
        background: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/profile-active.svg");
        background-size: cover;
    }

    ::before {
        content: "";
        position: absolute;
        left: -6px;
        width: 1px;
        height: 100%;
        background-color: #858585;
        border-radius: 6px;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;
const RighEnd = styled.div`
    display: flex;
    align-items: center;
    &.Rm {
        cursor: pointer;
    }
`;
const Container = styled.div`
    padding: 20px;
    background: #ffff;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
const Left = styled.div``;
const ImageContainer = styled(Link)`
    width: 150px !important;
    display: inline-block;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        display: none;
    }
`;

const Hamburger = styled.div`
    width: 30px;
    display: none;
    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    @media all and (max-width: 768px) {
        display: block;
    }
`;
