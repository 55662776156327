import React, { useState, useContext, useEffect, useRef } from "react";

// packages
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";

function SideBar() {
    const {
        dispatch,
        state: { user_data, aside_status },
    } = useContext(Context);

    const firstPath = window.location.pathname
    const [active, setActive] = useState("enquiry");
    const [isTenderManagementOpen, setIsTenderManagementOpen] = useState(false);
    const sidebar_status = aside_status.sidebar_status;
    const role = user_data.role;
    const wrapperRef = useRef(null);

    const handleExpand = (value) => {
        dispatch({
            type: "UPDATE_STATUS",
            aside_status: {
                sidebar_status: value,
            },
        });
    };

    const logoutFunction = () => {
        window.localStorage.clear();
        window.location.reload();
    };
    //----------------outside click------------------------
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !event.target.closest(".tender-management-menu")) {
                    handleExpand(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    return (
        <Main
            ref={wrapperRef}
            onMouseEnter={() => {
                handleExpand(true);
            }}
            onMouseLeave={() => {
                setIsTenderManagementOpen(false);
                handleExpand(false)
            }}
        >
            <Container className={sidebar_status ? "expand" : ""}>
                <SideButtons>
                    <Cover className={sidebar_status ? "expand" : ""}>
                        <ImageContainer to="/enquiry">
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/stickon-logo.svg"
                                alt="Logo"
                            />
                        </ImageContainer>
                        {role === "admin" && (
                            <>
                                <Button
                                    to="enquiry"
                                    onClick={() => setActive("enquiry")}
                                    className={firstPath === "/enquiry" ? "active" : ""}
                                >
                                    <Menu className="menu-item">
                                        {firstPath === "/enquiry" ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/profile3.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FEnquires-new-black.svg"
                                                alt="Icon"
                                            />
                                        )}

                                        <Title className={firstPath === "/enquiry" ? "active" : ""}>
                                            Enquiries
                                        </Title>
                                    </Menu>
                                </Button>

                                <Button
                                    to="/categories"
                                    onClick={() => setActive("categories")}
                                    className={firstPath === "/categories" ? "active" : ""}
                                >
                                    <Menu className="menu-item">
                                        {firstPath === "/categories" ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcategories-new-blue.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcategories%20new%20black.svg"
                                                alt="Icon"
                                            />
                                        )}

                                        <Title
                                            className={firstPath === "/categories" ? "active" : ""}
                                        >
                                            Categories
                                        </Title>
                                    </Menu>
                                </Button>

                                <Button
                                    to="/rm-profiles"
                                    onClick={() => setActive("rm-profiles")}
                                    className={firstPath === "/rm-profiles" ? "active" : ""}
                                >
                                    <Menu className="menu-item">
                                        {firstPath === "/rm-profiles" ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FRm-profiles-new-blue.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Frm-profiles-new-black.svg"
                                                alt="Icon"
                                            />
                                        )}

                                        <Title
                                            className={firstPath === "/rm-profiles" ? "active" : ""}
                                        >
                                            RM Profiles
                                        </Title>
                                    </Menu>
                                </Button>

                                <Button
                                    to="/customer"
                                    onClick={() => setActive("customer")}
                                    className={firstPath === "/customer" ? "active" : ""}
                                >
                                    <Menu className="menu-item">
                                        {firstPath === "/customer" ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcustomers-new-blue.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FCustomers-new-black.svg"
                                                alt="Icon"
                                            />
                                        )}

                                        <Title className={firstPath === "/customer" ? "active" : ""}>
                                            Customers
                                        </Title>
                                    </Menu>
                                </Button>
                                <Menu className={`menu-item tender-management-menu ${firstPath === "/tender-management" && "active"}`}
                                    onClick={() => setIsTenderManagementOpen(!isTenderManagementOpen)}
                                >
                                    {firstPath === "/tender-management" ? (
                                        <MenuItemIcons
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcustomers-new-blue.svg"
                                            alt="Icon"
                                        />
                                    ) : (
                                        <MenuItemIcon
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images%2FBil.svg"
                                            alt="Icon"
                                        />
                                    )}
                                    <DropDiv>
                                        <Title className={firstPath === "/tender-management" ? "active" : ""}>
                                            Tender management
                                        </Title>
                                        <DropIcon
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Farrowdown.svg"
                                            alt="Icon"
                                            isOpen={isTenderManagementOpen}
                                        />
                                    </DropDiv>
                                </Menu>
                                <Dropdown isOpen={isTenderManagementOpen}>
                                    <Button to="/tender-management/project-categories"
                                        className={firstPath === "/tender-management/project-categories" ? "active" : ""}
                                        >
                                        {firstPath === "/tender-management/project-categories" ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcategories-new-blue.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcategories%20new%20black.svg"
                                                alt="Icon"
                                            />
                                        )}
                                        <MenuItem>
                                            <Title>Project Categories</Title>
                                        </MenuItem>
                                    </Button>
                                    <Button to="/tender-management/projects"
                                        className={firstPath.includes("/tender-management/projects") ? "active" : ""}
                                    >
                                        {firstPath.includes("/tender-management/projects") ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcustomers-new-blue.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FCustomers-new-black.svg"
                                                alt="Icon"
                                            />
                                        )}
                                        <MenuItem>
                                            <Title>Projects</Title>
                                        </MenuItem>
                                    </Button>
                                    <Button to="/tender-management/tenders"
                                        className={firstPath.includes("/tender-management/tenders") ? "active" : ""}
                                        >
                                        {firstPath.includes("/tender-management/tenders") ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/profile3.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FEnquires-new-black.svg"
                                                alt="Icon"
                                            />
                                        )}
                                        <MenuItem>
                                            <Title>Tenders</Title>
                                        </MenuItem>
                                    </Button>
                                </Dropdown>
                                {/* <Button
                                    to="/payment"
                                    onClick={() => setActive("payment")}
                                    className={
                                        firstPath === "/payment" ? "active" : ""
                                    }
                                >
                                    <Menu className="menu-item">
                                        {firstPath === "/payment" ? (
                                            <MenuItemIcons
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FPayments-new-blue.svg"
                                                alt="Icon"
                                            />
                                        ) : (
                                            <MenuItemIcon
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fpayments-new-black.svg"
                                                alt="Icon"
                                            />
                                        )}

                                        <Title
                                            className={
                                                firstPath === "/payment"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            Payments
                                        </Title>
                                    </Menu>
                                </Button> */}
                            </>
                        )}

                        {/* ---------------------------------------------  Rm Side--------------------------------------------------------------- */}

                        {role === "relationship_manager" && (
                            <Button
                                to="rm-enquiry"
                                className={active === "enquiry" ? "active" : ""}
                            >
                                <Menu className="menu-item">
                                    {active === "enquiry" ? (
                                        <MenuItemIcons
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/profile3.svg"
                                            alt="Icon"
                                        />
                                    ) : (
                                        <MenuItemIcon
                                            src="https://stikcon-payments-static-and-media.sgp1.cdn.digitaloceanspaces.com/media/payment_receipt/enquiries.svg"
                                            alt="Icon"
                                        />
                                    )}
                                    <Title className={active === "enquiry" ? "active" : ""}>
                                        Enquiries
                                    </Title>
                                </Menu>
                            </Button>
                        )}

                        {/* ---------------------------------------------  Rm Side---------------------------------------------------------------  */}
                    </Cover>
                    {role === "admin" && (
                        <Button
                            to=""
                            onClick={() => {
                                logoutFunction();
                            }}
                        >
                            <Menu className="menu-item">
                                <MenuItemIcon
                                    className="logout"
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/logout-blue.svg"
                                    alt="Icon"
                                />
                                <Title>Logout</Title>
                            </Menu>
                        </Button>
                    )}
                </SideButtons>
            </Container>
        </Main>
    );
}

const Main = styled.div``;
const Title = styled.span`
    display: none;
    color: inherit;
    font-size: 16px;
    color: #747474;
    font-weight: 500;
    white-space: nowrap;
    font-family: "outfit_medium";
    &.active {
        color: #0880e0;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;

const DropDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`;
const DropIcon = styled.img`
    display: none;
    width: 10px;
    transition: all 0.5s ease;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};

    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const SideButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const Menu = styled.div`
    cursor: pointer;
    padding: 10px 21px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    &.active {
        transition: 0.3s ease;
        color: #fff;
        background: #deeefb;
        height: 46px;
        border-radius: 5px;

        ::before {
            content: "";
            position: absolute;
            left: 4px;
            z-index: 100;
            width: 3px;
            height: 30px;
            background-color: #0880e0;
            border-radius: 6px;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;
const MenuItem = styled.div`
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 19px;
    margin-right: 10px;

    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const MenuItemIcons = styled.img`
    display: block;
    width: 19px;
    margin-right: 10px;

    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const Button = styled(Link)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;

    margin: 5px 0;
    cursor: pointer;
    color: #0880e0;
    transition: 0.3s ease;
    position: relative;

    &.active {
        transition: 0.3s ease;
        color: #fff;
        background: #deeefb;

        ::before {
            content: "";
            position: absolute;
            left: 4px;
            z-index: 100;
            width: 3px;
            height: 30px;
            background-color: #0880e0;
            border-radius: 6px;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;
const Cover = styled.div`
    transition: all 0.4s ease;
    &.expand {
        padding: 0px 5px;
        ${Button} {
            border-radius: 5px;
        }
    }
`;
const Container = styled.aside`
    background: #fff;
    width: 65px;
    position: fixed;
    z-index: 8;
    top: 80px;
    left: 0;
    transition: all 0.4s ease;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    ${Title} {
        display: none;
    }
    &::-webkit-scrollbar {
        display: none;
    }

    &.expand {
        width: 245px;
        transition: all 0.4s ease;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        ${Title} {
            display: block;
        }
        ${DropIcon} {
            display: block;
        }
    }
    @media all and (max-width: 768px) {
        width: 0px;
    }
`;
const ImageContainer = styled(Link)`
    width: 130px;
    margin: 0 auto;
    cursor: pointer;
    margin-bottom: 15px;
    display: none;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        display: block;
    }
`;
const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    /* margin-left: 15px; */
    margin-left: 35px;
    transition: all 0.5s ease-in-out;
    /* background-color: #ececec; */
    border-radius: 8px;
    max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
    overflow: scroll;
    ${Button}{
        padding-left: 20px;
    }
`;

export default SideBar;
