import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

function CategoryFilter({ setStatus, filter, setFilter, filterRef }) {
    const [active, setActive] = useState("");
    const wrapperRef = useRef(null);

    //----------------outside click------------------------
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (
                        filterRef.current &&
                        filterRef.current.contains(event.target)
                    ) {
                    } else {
                        setFilter(false);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideClick(wrapperRef);

    return (
        <CoverFilter style={{ transform: filter && "scale(1,1)" }}>
            <Ref ref={wrapperRef}>
                <Active
                    onClick={() => {
                        setStatus("");
                        setActive("");
                    }}
                    className={!active ? "select" : ""}
                >
                    <Image>
                        {active === "" ? (
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/active.svg"
                                alt="Active icon"
                            />
                        ) : (
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/not-active.svg"
                                alt="icon"
                            />
                        )}
                    </Image>
                    <span>All</span>
                </Active>
                <Active
                    onClick={() => {
                        setStatus("True");
                        setActive("active");
                    }}
                    className={active === "active" ? "select" : ""}
                >
                    <Image>
                        {active === "active" ? (
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/active.svg"
                                alt="Active icon"
                            />
                        ) : (
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/not-active.svg"
                                alt="icon"
                            />
                        )}
                    </Image>
                    <span>Active</span>
                </Active>
                <Active
                    onClick={() => {
                        setActive("inactive");
                        setStatus("False");
                    }}
                    className={active === "inactive" ? "select" : ""}
                >
                    <Image>
                        {active === "inactive" ? (
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/active.svg"
                                alt="Active icon"
                            />
                        ) : (
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/not-active.svg"
                                alt="icon"
                            />
                        )}
                    </Image>
                    <span className={active === "active" ? "select" : ""}>
                        Inactive
                    </span>
                </Active>
            </Ref>
        </CoverFilter>
    );
}

export default CategoryFilter;
const CoverFilter = styled.div`
    background-color: #fff;
    position: absolute;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    z-index: 15;
    padding: 15px;
    width: 250px;
    top: 45px;
    right: 120px;
    transition: 0.3s;
    transform: scale(0, 0);

    :last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 640px) {
        top: 95px;
        right: 52%;
    }
    @media all and (max-width: 540px) {
        top: 95px;
        right: 35%;
    }
    @media all and (max-width: 420px) {
        top: 95px;
        right: 15%;
    }
    @media all and (max-width: 360px) {
        top: 95px;
        right: 12%;
    }
`;
const Ref = styled.div`
    display: flex;
    flex-direction: column;
`;
const Image = styled.div`
    margin-right: 10px;
    width: 20px;
    img {
        display: flex;
        width: 100%;
    }
`;
const Active = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 3px;
    color: #9c9c9c;
    position: relative;
    ::before {
        content: "";
        position: absolute;
        left: 0px;
        z-index: 100;
        width: 6px;
        height: 25px;
        background-color: #fff;
        border-radius: 6px;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    :nth-child(2) {
        margin-bottom: 0;
    }

    span {
        font-size: 16px;
    }
    &.select {
        background-color: #0880e0;
        color: #fff;
    }
`;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;

// const CoverFilter = styled.div``;
