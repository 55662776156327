import React, {
    useEffect,
    useState,
    Fragment,
    useRef,
} from "react";

//Packages
import styled from "styled-components";
import { useParams, useSearchParams } from "react-router-dom";

//Imports
import { debounce } from "lodash";
import NewProject from "../../modal/NewProject";
import UpdateProject from "../../modal/UpdateProject";
import DeleteProject from "../../modal/DeleteProject";
import ProjectDetails from "./ProjectComponents/ProjectDetails";
import ProjectAssets from "./ProjectComponents/ProjectAssets";
import ProjectEnquiries from "./ProjectComponents/ProjectEnquiries";

function ProjectSingle() {
    const [isBox, setBox] = useState(false);
    const [isUpdate, setUpdate] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [isAssets, setAssets] = useState(false);
    const [isTender, setTender] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [categoryPk, setCategoryPk] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [searchInput, setSearchInput] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAsset, setSelectedAsset] = useState();
    const [filter, setFilter] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [status, setStatus] = useState("");
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const enquiriesRef = useRef(null);

    const scrollToEnquiries = () => {
        console.log(enquiriesRef,"enquiries ref")
        if (enquiriesRef.current) {
            enquiriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleCategoryModal = () => {
        setBox(!isBox);
    };
    const handleUpdateModal = () => {
        setUpdate(!isUpdate);
    };
    const handleDeleteModal = () => {
        setDelete(!isDelete);
    };
    //--filter-----
    const handleFilter = () => {
        setFilter(!filter);
    };
    //---------- debounce -----------
    const handleText = debounce((text) => {
        setSearchInput(text);
    }, 600);

    useEffect(() => {
        const setInitialSearch = () => {
            const page = searchParams.get("page");
            setCurrentPage(page ? page : 1);
        };
        setInitialSearch();
        setCategoryPk(id);
    }, [searchParams]);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    return (
        <Fragment>
            <Container id="main">
                <SubContainer>
                    <EnquiryTopBar>
                        <EnquiryLeft>
                            <h2>Project Details</h2>
                            <span onClick={scrollToEnquiries} >Go to Enquiries</span>
                        </EnquiryLeft>
                    </EnquiryTopBar>
                    <ProjectMainContainer>
                        {/* Project Single Details */}
                        <ProjectDetails 
                            id={id}
                            isAssets={isAssets}
                            setAssets={setAssets}
                            setTender={setTender}
                            isDelete={isDelete}
                            handleDeleteModal={handleDeleteModal}
                            setDelete={setDelete}
                        />

                        {/* Assets */}
                        <ProjectAssets
                            refresh={refresh}
                            box={isBox}
                            setBox={setBox}
                            isDelete={isDelete}
                            setDelete={setDelete}
                            setTender={setTender}
                            isAssets={isAssets}
                            setAssets={setAssets}
                            handleDeleteModal={handleDeleteModal}
                            setSelectedAsset={setSelectedAsset}
                        />
                    </ProjectMainContainer>

                    {/* Enquiries */}
                    <ProjectEnquiries ref={enquiriesRef} setBox={setBox} />
                </SubContainer>
            </Container>
            <NewProject
                setRefresh={setrefresh}
                refresh={refresh}
                isBox={isBox}
                handleCategoryModal={handleCategoryModal}
                setBox={setBox}
                isAssets={true}
                projectId={id}
            />
            {/* <UpdateProject
                isUpdate={isUpdate}
                handleUpdateModal={handleUpdateModal}
                setUpdate={setUpdate}
                categoryPk={categoryPk}
                setCategoryPk={setCategoryPk}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                refresh={refresh}
                setRefresh={setrefresh}
                status={status}
                setStatus={setStatus}
                isTender={true}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
            /> */}
            <DeleteProject
                isDelete={isDelete}
                handleDeleteModal={handleDeleteModal}
                setDelete={setDelete}
                categoryPk={categoryPk}
                refresh={refresh}
                setrefresh={setrefresh}
                isTender={isTender}
                isAssets={isAssets}
                projectId={id}
                assetId={selectedAsset}
            />
        </Fragment>
    );
}

export default ProjectSingle;

const Container = styled.div``;
const EnquiryList = styled.div`
    overflow-x: scroll;
    @media all and (max-width: 1280px) {
        overflow-x: scroll;
    }
`;
const SubContainer = styled.div`
    width: 100%;
`;

const EnquiryTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
    @media all and (max-width: 480px) {
        padding: 10px 0px;
    }
`;

const FilterWrap = styled.div`
    display: flex;
    @media all and (max-width: 640px) {
        margin-top: 10px;
    }
`;

const ProjectMainContainer = styled.div`
    overflow-x: hidden;
    background-color: #fff;
    min-height: 100%;
    /* height: calc(100vh - 170px); */
    overflow-y: scroll;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    gap: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
`;
const EnquiryLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    @media all and (max-width: 640px) {
        margin-bottom: 10px;
    }
    h2 {
        font-size: 23px;
        color: #0a0a0a;
        font-family: "outfit_medium";
        display: inline-block;
    }
    span {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        background-color: #0880e0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #fff;
    }
`;

const AddNew = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: #0880e0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #fff;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const FilterIcon = styled.div`
    width: 20px;

    img {
        width: 100%;
        display: block;
    }
`;
const SearchIcon = styled.div`
    width: 22px;
    margin-right: 10px;
    display: flex;
    img {
        width: 100%;
        display: block;
    }
    input {
    }
`;
const ListHead = styled.div`
    display: flex;
    padding: 25px 0px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    z-index: 5;
    min-width: 1280px;

    & ::-webkit-scrollbar {
        display: none;
    }
`;

const HeadItem = styled.div`
    display: flex;
    color: #747474;
    font-family: "outfit_regular";
    font-size: 17px;
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")};
    /* width: 14%; */
    width: ${({ type }) =>
        type === "Category_name"
            ? "20%"
            : type === "project_category"
            ? "20%"
            : type === "LOS"
            ? "25%"
            : type === "tender_id"
            ? "20%"
            : "14%"};
    /* @media all and (max-width: 1280px) {
        width: ${({ type }) => (type === "action" ? "66%" : "17%")};
    }
    @media all and (max-width: 1180px) {
        width: ${({ type }) => (type === "action" ? "64%" : "18%")};
    }
    @media all and (max-width: 1080px) {
        width: ${({ type }) => (type === "action" ? "62%" : "19%")};
    }
    @media all and (max-width: 1080px) {
        width: ${({ type }) => (type === "action" ? "50%" : "25%")};
    }
    @media all and (max-width: 850px) {
        width: ${({ type }) => (type === "action" ? "44%" : "28%")};
    }
    @media all and (max-width: 768px) {
        width: ${({ type }) => (type === "action" ? "33.3%" : "33.3%")};
    } */
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 15px;
    position: relative;
    bottom: 0;
    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #48538d;
    font-size: 14px;
`;
const PaginationCover = styled.div``;
