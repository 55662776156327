import React, { forwardRef, Fragment, useContext, useEffect, useRef, useState } from "react";

//Packages
import { getYYMMDD } from "../../../general/helpers/function";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

//Imports
import { accountsConfig, supportsConfig } from "../../../../../AxiosConfig";
import SectionLoader from "../../../general/helpers/SectionLoader";
import NodataFound from "../../../includes/NodataFound";
import EnquiryFilter from "../../../includes/EnquiryFilter";
import DownloadFormat from "../../../modal/DownloadFormat";
import Enquirycard from "../../../includes/EnquiryCard";
import "react-datepicker/dist/react-datepicker.css";
import AssignModal from "../../../modal/AssignModal";
import { Context } from "../../../../contexts/Store";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import axios from "axios";
import QuotationCard from "../../../includes/quotations/QuotationCard";

const ProjectEnquiries = forwardRef((props, ref) => {
    const [getId, setGetId] = useState("");
    const [isModal, setModal] = useState(false);
    const [pagination, setPagination] = useState();
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [value, setValue] = useState("");
    const [enquiry, setEnquiry] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isCategory, setCategory] = useState("");
    const [isRMSelected, setRMSelected] = useState("");
    const [rmList, setRmList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isMode, setMode] = useState(false);
    const [enqNumber, setEnqNumber] = useState("");
    const [updateStatus, setUpdateStatus] = useState("");
    const [filter, setFilter] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setTodate] = useState("");
    const filterRef = useRef();
    const latestCancelToken = useRef(null);
    const { id } = useParams();

    const {
        dispatch,
        state: { user_data, enquiry_status },
    } = useContext(Context);
    const access_token = user_data.access_token;
    const status = enquiry_status.status;

    //---- ststus upfation----
    const handleStatus = (item) => {
        dispatch({
            type: "UPDATE_ENQUIRY_STATUS",
            enquiry_status: {
                status: item,
            },
        });
        setRefresh(!refresh);
    };

    useEffect(() => {
        const setInitialSearch = () => {
            const page = searchParams.get("page");
            setCurrentPage(page ? page : 1);
        };
        setInitialSearch();
    }, [searchParams]);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    //----------debounce -----------
    const handleText = debounce((text) => {
        setValue(text);
    }, 600);

    //------ filter ------
    const handleFilter = () => {
        setFilter(!filter);
    };
    //-------------Params------------------------------
    const getFilterParams = () => {
        let filterParams = {};
        filterParams.q = value;
        filterParams.enquiry_status = status;
        filterParams.from_date = getYYMMDD(fromDate);
        filterParams.to_date = getYYMMDD(toDate);
        filterParams.page = currentPage;
        filterParams.rm_username = isRMSelected;
        filterParams.category_id = isCategory;
        filterParams.pagination = "pagination";

        return filterParams;
    };
    //------------enquiries list----------------------------

    const fetchEnquiry = () => {
        setLoading(true);
        const filterParams = getFilterParams();
        const cancelTokenSource = axios.CancelToken.source();
        latestCancelToken.current = cancelTokenSource;
        supportsConfig
            .get(`api/v1/tenders/admin/projects/${id}/quotations`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                // params: filterParams,
                // cancelToken: cancelTokenSource.token,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setEnquiry(data.data);
                    setPagination(data.pagination_data);
                    setLoading(false);
                } else {
                    if (StatusCode === 6001) {
                        setLoading(false);
                        setEnquiry(null);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setEnquiry(null);
            });
    };
    useEffect(() => {
        if (latestCancelToken.current) {
            latestCancelToken.current.cancel("Previous request canceled");
        }
        fetchEnquiry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, value, refresh]);

    //------  RM List Api ------------.

    useEffect(() => {
        accountsConfig
            .get("api/v1/users/get-rm-list/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setRmList(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //------------Category List --------------
    useEffect(() => {
        supportsConfig
            .get("api/v1/enquiries/enquiry-category/list/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setCategoryList(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAssignModal = () => {
        setModal(!isModal);
    };

    return (
        <Fragment>
            <SubContainer ref={ref}>
                <EnquiryTopBar>
                    <EnquiryLeft>
                        <h2>Tenders</h2>
                    </EnquiryLeft>
                    <Enquiryright>
                        <InputField>
                            <SearchIcon>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fsearchicon-new-black.svg"
                                    alt="search"
                                />
                            </SearchIcon>
                            <input
                                type="text"
                                placeholder="Search"
                                onChange={(e) => handleText(e.target.value)}
                            />
                        </InputField>

                        <FilterField
                            ref={filterRef}
                            className={filter ? "filter" : ""}
                            onClick={() => handleFilter()}
                        >
                            <span className={filter ? "filter" : ""}>Filter</span>
                            <FilterIcon>
                                {filter ? (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Ffilter-new-white.svg"
                                        alt="filter"
                                    />
                                ) : (
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Ffiter-new.svg"
                                        alt="filter"
                                    />
                                )}
                            </FilterIcon>
                        </FilterField>
                        <EnquiryFilter
                            setRMSelected={setRMSelected}
                            categoryList={categoryList}
                            setCategory={setCategory}
                            setFromDate={setFromDate}
                            setRefresh={setRefresh}
                            filterRef={filterRef}
                            setFilter={setFilter}
                            setTodate={setTodate}
                            refresh={refresh}
                            filter={filter}
                            rmList={rmList}
                        />
                    </Enquiryright>
                </EnquiryTopBar>
                <StatusSection>
                    <LeftStatus>
                        <Unorder>
                            <List
                                type={status === "pending" ? "cl" : ""}
                                onClick={() => handleStatus("pending")}
                            >
                                Pending
                            </List>
                            <List
                                type={status === "active" ? "ac" : ""}
                                onClick={() => handleStatus("active")}
                            >
                                Active
                            </List>

                            <List
                                type={status === "closed" ? "pd" : ""}
                                onClick={() => handleStatus("closed")}
                            >
                                Closed
                            </List>
                        </Unorder>
                    </LeftStatus>
                </StatusSection>
                <EnquiryData>
                    <EnquiryList>
                        <ListHead>
                            <HeadItem type="Enquiry_no">Tender No.</HeadItem>
                            <HeadItem type="customer">Customer Name</HeadItem>
                            <HeadItem type="Catogery">Category</HeadItem>
                            <HeadItem type="Enquiry">Tender</HeadItem>
                            <HeadItem type="Latest">Latest Reply</HeadItem>
                            <HeadItem type="Assigned">Assigned</HeadItem>
                            <HeadItem type="action">Action</HeadItem>
                        </ListHead>
                        {loading ? (
                            <LoaderContainer>
                                <SectionLoader />
                            </LoaderContainer>
                        ) : enquiry?.length >= 1 ? (
                            enquiry.map((item, index) => {
                                return (
                                    <QuotationCard
                                        item={item}
                                        index={index}
                                        lines={item?.customer_name.split(" ")}
                                        key={item.id}
                                        isModal={isModal}
                                        setModal={setModal}
                                        setGetId={setGetId}
                                        setEnqNumber={setEnqNumber}
                                        setUpdateStatus={setUpdateStatus}
                                    />
                                );
                            })
                        ) : (
                            <LoaderContainer>
                                <NodataFound />
                            </LoaderContainer>
                        )}
                    </EnquiryList>

                    {pagination && pagination.total_pages > 1 && enquiry?.length > 0 && (
                        <PaginationCover>
                            <PaginationContainer>
                                <PaginationText>
                                    Showing Enquiries : {pagination?.first_item} -{" "}
                                    {pagination?.last_item} of {pagination?.total_items}{" "}
                                    Enquiries
                                </PaginationText>
                                <ReactPaginate
                                    previousLabel={""}
                                    nextLabel={""}
                                    pageCount={pagination.total_pages}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                />
                            </PaginationContainer>
                        </PaginationCover>
                    )}
                </EnquiryData>
            </SubContainer>
            <AssignModal
                getId={getId}
                refresh={refresh}
                isModal={isModal}
                setModal={setModal}
                enqNumber={enqNumber}
                setRefresh={setRefresh}
                updateStatus={updateStatus}
                handleAssignModal={handleAssignModal}
            />
            <DownloadFormat status="Enquiries" isMode={isMode} setMode={setMode} />
        </Fragment>
    );
});

export default ProjectEnquiries;

const LoaderContainer = styled.div`
    min-height: 300px;
    position: relative;
`
const EnquiryList = styled.div`
    /* min-height: 500px; */
    @media all and (max-width: 1280px) {
        overflow-x: scroll;
    }
`;
const SubContainer = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 10px 20px 20px;
    border-radius: 8px;
`;

const EnquiryTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
    @media all and (max-width: 480px) {
        padding: 10px 0px;
    }
`;

const StatusSection = styled.div`
    background-color: #f3f3f3;
    /* width: 103%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    margin-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
    @media all and (max-width: 480px) {
        flex-direction: column-reverse;
    }
`;

const EnquiryData = styled.div`
    overflow: hidden;
    background-color: #fff;
    min-height: 100%;
    /* height: calc(100vh - 200px); */
    /* overflow-y: scroll; */
    border-radius: 8px;
    padding: 15px;
    position: relative;
    justify-content: space-between;

    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const EnquiryLeft = styled.div`
    @media all and (max-width: 640px) {
        margin-bottom: 20px;
    }
    h2 {
        font-size: 23px;
        color: #0a0a0a;
        font-family: "outfit_medium";
        display: inline-block;
        border-bottom: 1px solid #ccc;
    }
`;
const Enquiryright = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    @media all and (max-width: 640px) {
        width: 100%;
        justify-content: space-between;
    }
    @media all and (max-width: 370px) {
        justify-content: unset;
    }

    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const InputField = styled.div`
    display: flex;
    background-color: #fff;
    padding: 10px 15px;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    @media all and (max-width: 640px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        margin-right: 0;
    }
    @media all and (max-width: 370px) {
        margin-right: 20px;
    }
    input {
        border: none;
        outline: none;
        -webkit-appearance: none;
        width: 200px;
        font-size: 15px;
        ::placeholder {
            color: #747474;
            font-size: 15px;
            font-family: "outfit_light";
        }
        @media all and (max-width: 640px) {
            width: 100%;
        }
        @media all and (max-width: 480px) {
            width: 100%;
        }
    }
`;
const FilterField = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #0a0a0a;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const FilterIcon = styled.div`
    width: 20px;

    img {
        width: 100%;
        display: block;
    }
`;
const SearchIcon = styled.div`
    width: 22px;
    margin-right: 10px;
    display: flex;
    img {
        width: 100%;
        display: block;
    }
    input {
    }
`;
const ListHead = styled.div`
    display: flex;
    padding: 25px 0px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    z-index: 5;
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        min-width: 1280px;
    }
`;
const RightStatus = styled.div`
    @media all and (max-width: 480px) {
        margin-bottom: 15px;
    }
`;
const Download = styled.button`
    display: flex;
    align-items: center;
    color: #004a85;
    font-size: 14px;
    background-color: #e2eaf9;
    padding: 10px 10px;
    justify-content: center;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    img {
        margin-left: 8px;
    }
`;

const LeftStatus = styled.div``;
const Unorder = styled.ul`
    display: flex;
    align-items: center;
`;
const List = styled.li`
    font-family: "outfit_regular";
    font-size: 18px;
    cursor: pointer;
    position: relative;
    margin-right: 18px;
    ::before {
        content: "";
        position: ${({ type }) =>
            type === "ac"
                ? "absolute"
                : type === "cl"
                ? "absolute"
                : type === "pd"
                ? "absolute"
                : "relative"};
        left: 0px;
        height: 4px;
        background-color: #0880e0;
        width: 100%;
        bottom: -9px;
    }

    :nth-child(3) {
        margin-right: 0;
    }
    color: ${({ type }) =>
        type === "ac"
            ? "#0884DF"
            : type === "cl"
            ? "#0884DF"
            : type === "pd"
            ? "#0884DF"
            : "#8B8B8B"};
`;
const HeadItem = styled.div`
    display: flex;
    color: #747474;
    font-family: "outfit_regular";
    font-size: 17px;
    width: ${({ type }) => (type === "customer" ? "20%" : "13.3%")};
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")}; ;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 15px;
    position: relative;
    bottom: 0;
    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #48538d;
    font-size: 14px;
`;
const PaginationCover = styled.div``;
