import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loaderAnimation from "../../../../assets/lotties/simpleloader.json";

export default function SimpleLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        // rendererSettings: {},
    };

    return (
        <Container>
            <Lottie options={defaultOptions} height={180} width={180} />
        </Container>
    );
}
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
