import React, { Suspense } from "react";
import { lazy } from "react";
import "../../../../assets/css/style.css";

// packages
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";

// components
import SideBar from "../../../ui/general/includes/Sidebar";
import SectionLoader from "../../../ui/general/helpers/SectionLoader";
import ProfilePage from "../../../ui/screens/Rm/screens/ProfilePage";

// lazy imports
const Enquiries = lazy(() => import("../../../ui/screens/admin/Enquiries"));
const Customer = lazy(() => import("../../../ui/screens/admin/Customer"));
const EnquiryPage = lazy(() => import("../../../ui/screens/admin/EnquiryPage"));
const Categories = lazy(() => import("../../../ui/screens/admin/Categories"));
const Projects = lazy(() => import("../../../ui/screens/general/Projects"));
const ProjectCategories = lazy(() => import("../../../ui/screens/general/ProjectCategories"));
const ProjectSingle = lazy(() => import("../../../ui/screens/general/ProjectSingle"));
const Quotations = lazy(() => import("../../../ui/screens/general/Quotations"));
const QuotationPage = lazy(() => import("../../../ui/screens/general/QuotationPage"));
const RmProfiles = lazy(() => import("../../../ui/screens/admin/RmProfiles"));
const CustomerSinglePage = lazy(() => import("../../../ui/screens/admin/CustomerSinglePage"));
const RmProfilesSinglePage = lazy(() => import("../../../ui/screens/admin/RmProfilesSinglePage"));

function AdminRouter() {
    return (
        <>
            <Suspense fallback={<SectionLoader />}>
                <Main>
                    <Routes>
                        <Route path="/" element={<Navigate to="/enquiry" />} />
                        <Route path="/enquiry" element={<Enquiries />} />
                        <Route path="/enquiry/:rm_id" element={<EnquiryPage />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/rm-profiles" element={<RmProfiles />} />
                        <Route path="/enquiry/profile" element={<ProfilePage />} />
                        <Route path="/customer" element={<Customer />} />
                        <Route path="/customer/:id" element={<CustomerSinglePage />} />
                        <Route path="/rm-profiles/:id" element={<RmProfilesSinglePage />} />
                        <Route path="/tender-management/projects" element={<Projects />} />
                        <Route path="/tender-management/projects/:id" element={<ProjectSingle />} />
                        <Route path="/tender-management/project-categories" element={<ProjectCategories />} />
                        <Route path="/tender-management/tenders" element={<Quotations />} />
                        <Route path="/tender-management/tenders/:id" element={<QuotationPage />} />
                    </Routes>
                    <SideBar />
                </Main>
            </Suspense>
        </>
    );
}

export default AdminRouter;
const Main = styled.div``;
