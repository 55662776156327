const ChiefReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_CUSTOMER_DATA":
            const customer_data = {
                ...state.customer_data,
                ...action.customer_data,
            };
            localStorage.setItem(
                "customer_data",
                JSON.stringify(customer_data)
            );
            return {
                ...state,
                customer_data: customer_data,
            };

        case "UPDATE_STATUS":
            const aside_status = {
                ...state.aside_status,
                ...action.aside_status,
            };
            return {
                ...state,
                aside_status: aside_status,
            };
        case "UPDATE_ACTIVE_MENU":
            return {
                ...state,
                active_menu: action.active_menu,
            };
        case "UPDATE_ENQUIRY_STATUS":
            return {
                ...state,
                enquiry_status: action.enquiry_status,
            };
        case "UPDATE_SCROLL":
            const scroll = { ...state.scroll, ...action.scroll };
            // localStorage.setItem("enquiry_datas", JSON.stringify(enquiry_datas));
            return {
                ...state,
                scroll: scroll,
            };
        default:
            return state;
    }
};

export default ChiefReducer;
