import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import SectionLoader from "../../../ui/general/helpers/SectionLoader";
import RmEnquiries from "../../../ui/screens/Rm/screens/RmEnquiries";
import ProjectCategories from "../../../ui/screens/general/ProjectCategories";
import Projects from "../../../ui/screens/general/Projects";
import ProjectSingle from "../../../ui/screens/general/ProjectSingle";
const ProfilePage = lazy(() =>
    import("../../../ui/screens/Rm/screens/ProfilePage")
);
const RmEnquiryPage = lazy(() =>
    import("../../../ui/screens/Rm/screens/RmEnquiryPage")
);

function RmRouter() {
    return (
        <Suspense fallback={<SectionLoader />}>
            <Main>
                <Routes>
                    <Route path="/enquiry" element={<RmEnquiries />} />
                    <Route path="/enquiry/:rm_id" element={<RmEnquiryPage />} />
                    <Route path="/enquiry/profile" element={<ProfilePage />} />
                    <Route path="/tender-management/projects" element={<Projects />} />
                    <Route path="/tender-management/projects/:id" element={<ProjectSingle />} />
                    <Route path="/tender-management/project-categories" element={<ProjectCategories />} />
                    <Route path="/tender-management/enquiries" element={<RmEnquiries />} />
                </Routes>
            </Main>
        </Suspense>
    );
}

export default RmRouter;
const Main = styled.div``;
