import React, { Fragment, useState } from "react";
import styled from "styled-components";
import arrow from "../../../../assets/images/login-back-arrow.svg";
import { accountsConfig } from "../../../../AxiosConfig";
import ButtonLoading from "../../general/helpers/ButtonLoading";
import ForgotSuccesModal from "../../modal/ForgotSuccessModal";

function ForgotPassword({ setForgot, change }) {
    const [emailValue, setEmailValue] = useState("");
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState("");
    const [isLoad, setLoad] = useState(false);
    const [isMode, setMode] = useState(false);
    const [message, setMessage] = useState("");

    // forgot password api
    const submitHandler = () => {
        if (emailValue) {
            setLoad(true);
            const formData = new FormData();
            formData.append(`profile_type`, "admin");
            formData.append(`username`, emailValue);

            accountsConfig
                .post("api/v1/users/login/reset-password/", formData)
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoad(false);
                        setMode(true);
                        setEmailValue("");
                        setMessage(data.message.match(/\b[\w.-]+@[\w.-]+\.\w{2,}\b/)[0]);
                    } else {
                        setErrorStatus(data.message);
                        setLoad(false);
                        setMessage("");
                    }
                })
                .catch((error) => {
                    setLoad(false);
                });
        } else {
            setError(true);
        }
    };

    // enter key press
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            submitHandler();
            event.preventDefault();
        }
    };

    return (
        <Fragment>
            <RightContainer>
                <RightHead>
                    <h2>Forgot password?</h2>
                    <p>
                        No worries, we’ll send you reset instructions. Check your email to get a new
                        password.
                    </p>
                </RightHead>

                <Form>
                    <Cover>
                        <EmailText>Enter your username</EmailText>
                        <EmailCover>
                            <InputField
                                type="email"
                                onChange={(e) => {
                                    setEmailValue(e.target.value);
                                    setErrorStatus("");
                                }}
                                onKeyDown={handleKeyDown}
                                value={emailValue}
                            />
                        </EmailCover>
                        {error && <ErrorMessage>This field is required</ErrorMessage>}
                        {errorStatus && <ErrorMessage>{errorStatus}</ErrorMessage>}
                    </Cover>

                    <Button onClick={submitHandler}>
                        {isLoad ? <ButtonLoading /> : "Get a new password"}
                    </Button>
                    <Back onClick={() => setForgot(false)}>
                        <img src={arrow} alt="icon" />
                        <p>Back to login</p>
                    </Back>
                </Form>
            </RightContainer>
            <ForgotSuccesModal isMode={isMode} setMode={setMode} message={message} />
        </Fragment>
    );
}

export default ForgotPassword;
const Back = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        margin-top: 13px;
    }
    img {
        width: 15px;
        @media all and (max-width: 480px) {
            width: 13px;
        }
    }
    p {
        margin-left: 10px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
`;

const EmailCover = styled.div`
    height: 50px;
    border: 1px solid #e0e0e0;
    opacity: 0.5;
    width: 100%;
    @media all and (max-width: 480px) {
        height: 40px;
    }
`;
const Form = styled.form`
    width: 100%;
`;
const Cover = styled.div`
    margin-bottom: 20px;
    position: relative;
    &:nth-child(2) {
        margin-bottom: 0;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 15px;
    }
`;
const EmailText = styled.div`
    color: #929292;
    margin-bottom: 5px;
    font-family: "outfit_light";
    font-size: 16px;
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;
const InputField = styled.input`
    width: 100%;
    height: 100%;
    font-size: 16px;
    background: #fff !important;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    outline-color: #0883e0;
`;
const Button = styled.div`
    background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    margin-top: 30px;
    font-family: "outfit_medium";
    font-size: 16px;
    cursor: pointer;

    @media all and (max-width: 480px) {
        height: 40px;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 13px;
    height: 30px;
    position: absolute;
    top: 100%;
    display: inline;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const RightContainer = styled.div`
    width: 80%;
    @media all and (max-width: 980px) {
        width: 95%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;

const RightHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    h2 {
        font-family: "outfit_medium";
        display: inline-block;
        color: #606060;
        font-size: 25px;
    }
    p {
        color: #929292;
        font-size: 15px;
        margin-top: 10px;
        max-width: 80%;
    }
`;
