import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// packages
import styled from "styled-components";
import { getDateStr, getTimeFromDate } from "../general/helpers/function";

function EnquiryCard({
    item,
    lines,
    index,
    isModal,
    setModal,
    setGetId,
    setEnqNumber,
    setUpdateStatus,
}) {
    const date = getDateStr(new window.Date());

    // ------  truncate  --------
    function truncate(str, length) {
        if (str?.length > length) {
            return str.slice(0, length) + "...";
        } else return str;
    }
    //---- hupdate modal  ------

    const handleUpdate = (item, obj) => {
        item ? setUpdateStatus("update") : setUpdateStatus("assign");
        setModal(!isModal);
        setEnqNumber(obj.enquiry_number);
        setGetId(obj.id);
    };

    return (
        <Fragment>
            <Container>
                <ContanerList type="Enquiry_no">
                    {!item.is_admin_read && <span></span>}
                    {item.enquiry_number}
                </ContanerList>
                <ContanerList type="customer">
                    {item?.user_data?.image === "null" ? (
                        <Background type={index}>
                            {lines && lines[0][0]}
                            {lines[1] && lines[1][0]}
                        </Background>
                    ) : (
                        <Background
                            style={{
                                backgroundImage: `url(${item?.user_data?.image})`,
                            }}
                            type={index}
                        ></Background>
                    )}

                    <TextContent>
                        <Text> {item.user_data?.name}</Text>
                        <Dates>
                            <CalederIcon>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcalendericon-new.svg"
                                    alt="calender"
                                />
                            </CalederIcon>
                            <Date>
                                {date === getDateStr(item?.latest_reply?.date_added)
                                    ? `Today at ${getTimeFromDate(item?.latest_reply?.date_added)} `
                                    : getDateStr(item?.latest_reply?.date_added)}
                            </Date>
                        </Dates>
                    </TextContent>
                </ContanerList>
                <ContanerList type="Catogery">{truncate(item.category_title, 15)}</ContanerList>
                <Enquiry type="Enquiry">
                    <Subtext>{truncate(item.title, 15)}</Subtext>
                    <Description>{truncate(item.description, 15)}</Description>
                </Enquiry>
                <ContanerList type="Latest">
                    {item?.latest_reply?.message ? truncate(item.latest_reply?.message, 15) : "--"}
                </ContanerList>
                <Assigned type="Assigned">
                    <span onClick={() => handleUpdate(item.rm_name, item)}>
                        {item.rm_data?.name ? item.rm_data?.name : "Rm Not-Assigned"}
                        <Arrow>
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fassigned-blue-arrow.svg"
                                alt="arrow"
                            />
                        </Arrow>
                    </span>
                </Assigned>
                <View type="action" to={`/enquiry/${item.id}`}>
                    <span>View Chat</span>
                </View>
            </Container>
        </Fragment>
    );
}

export default EnquiryCard;

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0px;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
    @media all and (max-width: 1280px) {
        min-width: 1280px;
    }
`;
const ContanerList = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #0a0a0a;
    font-size: 17px;
    font-family: "outfit_regular";
    display: flex;
    width: ${({ type }) => (type === "customer" ? "20%" : "13.3%")};
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")};
    span {
        width: 9px;
        height: 9px;
        background-color: #0880e0;
        border-radius: 50%;
        margin-right: 6px;
    }
`;
const Background = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #ffff;
    background-color: ${({ type }) => (type % 2 === 0 ? "#0880e0" : "#05E8BA")};
`;
const TextContent = styled.div`
    display: flex;
    flex-direction: column;
`;
const Text = styled.div`
    text-transform: capitalize;
`;
const Dates = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
`;
const CalederIcon = styled.div`
    width: 11px;
    margin-right: 4px;
    img {
        display: block;
        width: 100%;
    }
`;
const Date = styled.div`
    color: #808080;
    font-size: 13px;
    text-transform: lowercase;

    ::first-letter {
        text-transform: uppercase;
    }
`;
const Subtext = styled.div``;
const Description = styled.p`
    font-size: 15px;
`;
const Enquiry = styled.div`
    color: #0a0a0a;
    font-size: 17px;
    font-family: "outfit_regular";
    display: flex;
    flex-direction: column;
    width: ${({ type }) => (type === "customer" ? "20%" : "13.3%")};
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")}; ;
`;
const View = styled(Link)`
    color: #087ee1;
    font-size: 17px;
    font-family: "outfit_regular";
    display: flex;
    cursor: pointer;
    width: ${({ type }) => (type === "customer" ? "20%" : "13.3%")};
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")};
    span {
        border: 1.5px solid #087ee1;
        padding: 8px 10px;
        border-radius: 5px;
    }
`;
const Assigned = styled.div`
    color: #087ee1;
    font-size: 17px;
    font-family: "outfit_regular";
    display: flex;
    align-items: center;
    position: relative;
    width: ${({ type }) => (type === "customer" ? "20%" : "13.3%")};
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")};
    cursor: pointer;
    span {
        align-items: center;
        display: flex;
        position: relative;
        ::before {
            content: "";
            position: absolute;
            left: 0px;
            height: 1.5px;
            background-color: #0880e0;
            width: 100%;
            bottom: -9px;
        }
    }
`;
const Arrow = styled.div`
    width: 20px;
    margin-left: 5px;

    img {
        display: block;
        width: 100%;
    }
`;
