import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../ui/general/helpers/SectionLoader";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "./AppRouter";
import AuthRouter from "./AuthRouter";

function MainRouter() {
    const [isLoading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const { dispatch } = useContext(Context);

    window.onfocus = function () {
        setUpdate(!update);
    };

    useEffect(() => {
        let user_data = localStorage.getItem("user_data");
        user_data = JSON.parse(user_data);
        dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
        setTimeout(() => setLoading(false), 1100);
    }, [dispatch, update]);

    return isLoading ? (
        <SectionLoader />
    ) : (
        <Routes>
            <Route
                path="auth/*"
                element={
                    <AuthRoute>
                        <AuthRouter />
                    </AuthRoute>
                }
            />
            <Route
                path="/*"
                element={
                    <PrivateRoute>
                        <AppRouter />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export default MainRouter;
