import React, { Fragment } from "react";

//--- package ----
import styled from "styled-components";
import { getYYMMDDIST } from "../general/helpers/function";
import { useNavigate } from "react-router-dom";

function CategoryCard({
    item,
    setCategoryName,
    setUpdate,
    setCategoryPk,
    setDelete,
    setStatus,
    isTender,
    setSelectedData,
}) {
    const navigate = useNavigate();
    return (
        <Fragment>
            <Container isTender={isTender}>
                <ContainerList type="Category_name">
                    <ListImage>
                        <img
                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcategoriname-new-icon.svg"
                            alt="list"
                        />
                    </ListImage>
                    <span>{isTender ? item.name : item.title}</span>
                </ContainerList>
                {isTender && (
                    <ContainerList type="tender_id">
                        <span>{item.tender_id}</span>
                    </ContainerList>
                )}
                {/* {isTender && (
                    <ContainerList type="">
                        <Status
                            className={
                                item.project_status !== "10" ? "inactive" : ""
                            }
                        >
                            {item.project_status == "10"
                                ? "Active"
                                : "Closed"}
                        </Status>
                    </ContainerList>
                )} */}
                {isTender && (
                    <ContainerList type="project_category">
                        <span>{item.project_category_name}</span>
                    </ContainerList>
                )}
                {isTender && (
                    <ContainerList type="LOS">
                        <span>
                            {getYYMMDDIST(item.last_date_of_submission)}
                        </span>
                    </ContainerList>
                )}
                <ContainerList type="status">
                    <Status className={!item.status ? "inactive" : ""}>
                        {item.status ? "Active" : "Inactive"}
                    </Status>
                </ContainerList>
                <ContainerList type="action" isTender={isTender}>
                    <UpdateCategory
                        onClick={() => {
                            if (isTender) {
                                navigate(`/tender-management/projects/${item.id}`)
                            } else {
                                setCategoryName(item.title);
                                setUpdate(true);
                                setCategoryPk(item.id);
                                setStatus(item.status);
                                setSelectedData(item);
                            }
                        }}
                    >
                        <img
                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/edit.svg"
                            alt="icon"
                        />
                    </UpdateCategory>
                    <DeleteCategory
                        onClick={() => {
                            setDelete(true);
                            setCategoryPk(item.id);
                        }}
                    >
                        <img
                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/delete.svg"
                            alt="icon"
                        />
                    </DeleteCategory>
                </ContainerList>
            </Container>
        </Fragment>
    );
}

export default CategoryCard;
const Container = styled.div`
    display: flex;
    padding: 20px 0px;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
    justify-content: space-between;
    min-width: ${(props) => (props.isTender ? "1280px" : "auto")};
    @media all and (max-width: 640px) {
        min-width: ${(props) => (props.isTender ? "1280px" : "640px")};
    }
`;
const UpdateCategory = styled.div`
    width: 23px;
    cursor: pointer;
    margin-right: 10px;
    img {
        width: 100%;
        display: block;
    }
`;
const DeleteCategory = styled.div`
    cursor: pointer;
    width: 23px;
    img {
        width: 100%;
        display: block;
    }
`;
const Status = styled.div`
    color: #20ae6d;
    background-color: #e7f8f0;
    padding: 3px 5px;
    border-radius: 4px;
    &.inactive {
        color: #a5a5a5;
        background-color: #f7f7f7;
    }
`;
const ListImage = styled.div`
    width: 23px;
    margin-right: 10px;
    img {
        width: 100%;
        display: block;
    }
`;
const ContainerList = styled.div`
    text-transform: capitalize;
    color: #0a0a0a;
    font-size: 17px;
    font-family: "outfit_regular";
    align-items: center;
    display: flex;
    /* width: 14%; */
    width: ${({ type, isTender }) =>
        type === "Category_name"
            ? "20%"
            : type === "project_category"
            ? "20%"
            : type === "LOS"
            ? "25%"
            : type === "tender_id"
            ? "20%"
            : type === "action" && !isTender
            ? "68%"
            : "14%"};
    /* width: ${({ type }) => (type === "action" ? "68%" : "16%")}; */
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")};
    @media all and (max-width: 1280px) {
        width: ${({ type, isTender }) =>
            type === "action" && !isTender && "66%"};
    }
    @media all and (max-width: 1180px) {
        width: ${({ type, isTender }) =>
            type === "action" && !isTender && "64%"};
    }
    @media all and (max-width: 1080px) {
        width: ${({ type, isTender }) =>
            type === "action" && !isTender && "62%"};
    }
    @media all and (max-width: 1080px) {
        width: ${({ type, isTender }) =>
            type === "action" && !isTender && "50%"};
    }
    @media all and (max-width: 850px) {
        width: ${({ type, isTender }) =>
            type === "action" && !isTender && "44%"};
    }
    @media all and (max-width: 768px) {
        width: ${({ type, isTender }) =>
            type === "action" && !isTender && "33.3%"};
    }
`;
