import React from "react";
import styled from "styled-components";

function LogOutModal({ isLogout, setLogout }) {
    //--- logout-----
    const logoutFunction = (e) => {
        e.preventDefault();
        window.localStorage.clear();
        window.location.reload();
    };

    return (
        <MainContainer>
            {isLogout ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: isLogout && "scale(1,1)" }}>
                ~
                <Modal>
                    <Container>
                        <TopContainer>
                            <CoverText>Logout Your Profile</CoverText>
                            <ImageContainer onClick={() => setLogout(false)}>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                    alt="Cross"
                                />
                            </ImageContainer>
                        </TopContainer>
                        <CautionNote>
                            <span>
                                Are you sure,
                                <br />
                                you want to Logout your Profile?
                            </span>
                        </CautionNote>
                        <CoverSubmit>
                            <Cancel onClick={() => setLogout(false)}>
                                Cancel
                            </Cancel>
                            <Submit onClick={logoutFunction}>Logout</Submit>
                        </CoverSubmit>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default LogOutModal;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1020;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 1002;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    background: #fff;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow-y: hidden;
`;
const MainContainer = styled.div`
    z-index: 1002;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: baseline;
    border-bottom: 1px solid #ebebeb;
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    & img {
        display: block;
        width: 100%;
    }
`;
const CoverSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    @media all and (max-width: 640px) {
        justify-content: center;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
        margin-top: 30px;
    }
`;
const Cancel = styled.div`
    background: #fff;
    width: 135px;
    height: 45px;
    color: #0880e0;
    border: 1px solid #0880e0;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 480px) {
        margin-right: 0;
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Submit = styled.div`
    background: #0880e0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 45px;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    @media all and (max-width: 480px) {
        margin-top: 10px;
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Container = styled.div`
    padding: 30px;
    @media all and (max-width: 480px) {
        padding: 30px 20px;
    }
`;
const CoverText = styled.h3`
    color: #1a6eb0;
    margin-bottom: 25px !important;
`;
const CautionNote = styled.p`
    span {
        color: #707070;
        display: inline-block;
    }
`;
