import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";

function ViewPhotoModal({ preview, setPreview, data }) {
    const wrapperRef = useRef();
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPreview(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    // Outside Scroll
    useEffect(() => {
        if (preview) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [preview]);
    return (
        <MainContainer>
            {preview ? <Overlay onClick={() => setPreview(false)}></Overlay> : ""}
            <BackContainer style={{ transform: preview && "scale(1,1)" }}>
                <Modal>
                    <Cover
                        ref={wrapperRef}
                        style={{
                            backgroundImage: `url(${data?.photo})`,
                        }}
                    ></Cover>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default ViewPhotoModal;
const MainContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    max-height: 500px;
    background: transparent;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

const Cover = styled.div`
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 580px;
    height: 500px;
`;
