import React, { Fragment, useEffect, useRef } from "react";

// Package
import styled from "styled-components";

function RmProfileList({
    setPreview,
    setWebcam,
    setImgSrc,
    setRemove,
    setupload,
    setCrop,
    listRef,
    setList,
    data,
}) {
    const wrapperRef = useRef(null);

    //------outside click-------------
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (listRef.current && listRef.current.contains(event.target)) {
                    } else {
                        setList(false);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    //--- photo view ---
    const handlePhoto = (name) => {
        if (data?.photo) {
            setPreview(true);
            setList(false);
        }
    };

    const handleRemove = () => {
        if (data?.photo) {
            setupload("True");
            setList(false);
            setRemove(true);
        }
    };

    const handleUpload = () => {
        document.getElementById("crop").click();
    };

    // Select Crop image
    function onSelectFile(e) {
        setList(false);
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () => setImgSrc(reader.result.toString() || ""));
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <Fragment>
            <Container ref={wrapperRef}>
                <Lists onClick={handlePhoto}>View Photo</Lists>
                <Lists onClick={() => setWebcam(true)}>Take Photo</Lists>
                <Lists onClick={handleUpload}>Upload Photo</Lists>
                <Lists onClick={handleRemove}>Remove Photo</Lists>
            </Container>
            <input
                style={{ display: "none" }}
                accept="image/*"
                id="crop"
                type="file"
                onChange={(e) => onSelectFile(e)}
            />
        </Fragment>
    );
}

export default RmProfileList;
const Container = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 10%);
    border-radius: 4px;
    width: 160px;
    height: 150px;
    position: absolute;
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    top: 135px;
    left: 140px;
    @media all and (max-width: 488px) {
        top: 160px;
        left: 0px;
    }
`;

const Lists = styled.p`
    color: #747474;
    font-size: 15px;
    margin-top: 6px;
    cursor: pointer;
    :nth-child(1) {
        margin-top: 0;
    }
`;
