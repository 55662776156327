import React, { useContext, useEffect, useState, Fragment, useRef } from "react";

//Packages
import styled from "styled-components";
import NewCategory from "../../modal/NewCategory";
import UpdateCategoryModal from "../../modal/UpdateCategoryModal";
import DeleteCategoryModal from "../../modal/DeleteCategoryModal";
import { useSearchParams } from "react-router-dom";

//Imports
import { Context } from "../../../contexts/Store";
import { supportsConfig } from "../../../../AxiosConfig";
import ReactPaginate from "react-paginate";
import SectionLoader from "../../general/helpers/SectionLoader";
import { debounce } from "lodash";
import CategoryCard from "../../includes/CategoryCard";
import CategoryFilter from "../../includes/CategoryFilter";
import NodataFound from "../../includes/NodataFound";

function ProjectCategories() {
    const [isBox, setBox] = useState(false);
    const [isUpdate, setUpdate] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [projectCategories, setProjectCategories] = useState([]);
    const [categoryPk, setCategoryPk] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [searchInput, setSearchInput] = useState();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setActive] = useState("");
    const [filter, setFilter] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [status, setStatus] = useState("");
    const [searchParams] = useSearchParams();
    const filterRef = useRef();

    const handleCategoryModal = () => {
        setBox(!isBox);
    };
    const handleUpdateModal = () => {
        setUpdate(!isUpdate);
    };
    const handleDeleteModal = () => {
        setDelete(!isDelete);
    };
    //--filter-----
    const handleFilter = () => {
        setFilter(!filter);
    };
    //---------- debounce -----------
    const handleText = debounce((text) => {
        setSearchInput(text);
    }, 600);

    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;

    useEffect(() => {
        const setInitialSearch = () => {
            const page = searchParams.get("page");
            setCurrentPage(page ? page : 1);
        };
        setInitialSearch();
    }, [searchParams]);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }
    const handleProjectCategories = () => {
        setLoading(true);
        supportsConfig
            .get("api/v1/tenders/admin/project-categories/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    filter_status: isActive,
                    q: searchInput,
                    page: currentPage,
                    pagination: "pagination",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPagination(data.pagination_data);
                    setProjectCategories(data.data);
                    setLoading(false);
                } else {
                    if (StatusCode === 6001) {
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //------------------------get Category list api--------------------------------------
    useEffect(() => {
        handleProjectCategories();
    }, [isActive, searchInput, currentPage, refresh, access_token]);

    return (
        <Fragment>
            <Container id="main">
                <SubContainer>
                    <EnquiryTopBar>
                        <EnquiryLeft>
                            <h2>Project Categories</h2>
                        </EnquiryLeft>
                        <EnquiryRight>
                            <InputField>
                                <SearchIcon>
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fsearchicon-new-black.svg"
                                        alt="search"
                                    />
                                </SearchIcon>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => handleText(e.target.value)}
                                />
                            </InputField>
                            <FilterWrap>
                                <FilterField
                                    ref={filterRef}
                                    onClick={handleFilter}
                                    className={filter ? "filter" : ""}
                                >
                                    <span className={filter ? "filter" : ""}>Filter</span>
                                    <FilterIcon>
                                        {filter ? (
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Ffilter-new-white.svg"
                                                alt="filter"
                                            />
                                        ) : (
                                            <img
                                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Ffiter-new.svg"
                                                alt="filter"
                                            />
                                        )}
                                    </FilterIcon>
                                </FilterField>
                                <AddNew onClick={() => setBox(true)}>
                                    <span>Add New</span>
                                    <FilterIcon>
                                        <img
                                            src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2FAdd-New-icon.svg"
                                            alt="filter"
                                        />
                                    </FilterIcon>
                                </AddNew>
                                <CategoryFilter
                                    setStatus={setActive}
                                    filter={filter}
                                    setFilter={setFilter}
                                    filterRef={filterRef}
                                />
                            </FilterWrap>
                        </EnquiryRight>
                    </EnquiryTopBar>

                    <EnquiryData>
                        <EnquiryList>
                            <ListHead>
                                <HeadItem type="Category_name">Category Name</HeadItem>
                                <HeadItem type="status">Status</HeadItem>
                                <HeadItem type="action">Action</HeadItem>
                            </ListHead>
                            {loading ? (
                                <SectionLoader />
                            ) : projectCategories.length >= 1 ? (
                                projectCategories.map((item) => (
                                    <CategoryCard
                                        key={item.id}
                                        item={item}
                                        setCategoryName={setCategoryName}
                                        setUpdate={setUpdate}
                                        setCategoryPk={setCategoryPk}
                                        setDelete={setDelete}
                                        setStatus={setStatus}
                                    />
                                ))
                            ) : (
                                <NodataFound />
                            )}
                        </EnquiryList>

                        {pagination && pagination.total_pages > 1 && projectCategories.length > 0 && (
                            <PaginationCover>
                                <PaginationContainer>
                                    <PaginationText>
                                        Showing Enquiries : {pagination?.first_item} -{" "}
                                        {pagination?.last_item} of {pagination?.total_items}{" "}
                                        Enquiries
                                    </PaginationText>
                                    <ReactPaginate
                                        previousLabel={""}
                                        nextLabel={""}
                                        pageCount={pagination.total_pages}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                    />
                                </PaginationContainer>
                            </PaginationCover>
                        )}
                    </EnquiryData>
                </SubContainer>
            </Container>
            <NewCategory
                setRefresh={setrefresh}
                refresh={refresh}
                isBox={isBox}
                handleCategoryModal={handleCategoryModal}
                setBox={setBox}
                isTender={true}
            />
            <UpdateCategoryModal
                isUpdate={isUpdate}
                handleUpdateModal={handleUpdateModal}
                setUpdate={setUpdate}
                categoryPk={categoryPk}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                refresh={refresh}
                setrefresh={setrefresh}
                status={status}
                setStatus={setStatus}
                isTender={true}
            />
            <DeleteCategoryModal
                isDelete={isDelete}
                handleDeleteModal={handleDeleteModal}
                setDelete={setDelete}
                categoryPk={categoryPk}
                refresh={refresh}
                setrefresh={setrefresh}
                isTender={true}
            />
        </Fragment>
    );
}

export default ProjectCategories;

const Container = styled.div``;
const EnquiryList = styled.div`
    @media all and (max-width: 1280px) {
        overflow-x: scroll;
    }
`;
const SubContainer = styled.div`
    width: 100%;
`;

const EnquiryTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
    @media all and (max-width: 480px) {
        padding: 10px 0px;
    }
`;

const FilterWrap = styled.div`
    display: flex;
    @media all and (max-width: 640px) {
        margin-top: 10px;
    }
`;

const EnquiryData = styled.div`
    overflow-x: hidden;
    background-color: #fff;
    min-height: 100%;
    height: calc(100vh - 170px);
    overflow-y: scroll;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    justify-content: space-between;

    display: flex;
    flex-direction: column;
`;

const EnquiryLeft = styled.div`
    @media all and (max-width: 640px) {
        margin-bottom: 10px;
    }
    h2 {
        font-size: 23px;
        color: #0a0a0a;
        font-family: "outfit_medium";
        display: inline-block;
    }
`;
const EnquiryRight = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    @media all and (max-width: 640px) {
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
    @media all and (max-width: 370px) {
        justify-content: unset;
    }

    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const InputField = styled.div`
    display: flex;
    background-color: #fff;
    padding: 10px 15px;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    @media all and (max-width: 640px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        margin-right: 0;
        width: 90%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
    input {
        border: none;
        outline: none;
        -webkit-appearance: none;
        width: 200px;
        font-size: 15px;
        ::placeholder {
            color: #747474;
            font-size: 15px;
            font-family: "outfit_light";
        }
        @media all and (max-width: 640px) {
            width: 100%;
        }
        @media all and (max-width: 480px) {
            width: 100%;
        }
    }
`;
const FilterField = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    &.filter {
        background-color: #0880e0;
        color: #ffff;
    }

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #0a0a0a;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;

const AddNew = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: #0880e0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #fff;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const FilterIcon = styled.div`
    width: 20px;

    img {
        width: 100%;
        display: block;
    }
`;
const SearchIcon = styled.div`
    width: 22px;
    margin-right: 10px;
    display: flex;
    img {
        width: 100%;
        display: block;
    }
    input {
    }
`;
const ListHead = styled.div`
    display: flex;
    padding: 25px 0px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    z-index: 5;
    & ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 640px) {
        min-width: 640px;
    }
`;

const HeadItem = styled.div`
    display: flex;
    color: #747474;
    font-family: "outfit_regular";
    font-size: 17px;
    width: ${({ type }) => (type === "Category_name" ? "20%" : type === "action" ? "68%" : "16%")};
    justify-content: ${({ type }) => (type === "action" ? "flex-end" : "")};
    @media all and (max-width: 1280px) {
        width: ${({ type }) => (type === "action" ? "66%" : "17%")};
    }
    @media all and (max-width: 1180px) {
        width: ${({ type }) => (type === "action" ? "64%" : "18%")};
    }
    @media all and (max-width: 1080px) {
        width: ${({ type }) => (type === "action" ? "62%" : "19%")};
    }
    @media all and (max-width: 1080px) {
        width: ${({ type }) => (type === "action" ? "50%" : "25%")};
    }
    @media all and (max-width: 850px) {
        width: ${({ type }) => (type === "action" ? "44%" : "28%")};
    }
    @media all and (max-width: 768px) {
        width: ${({ type }) => (type === "action" ? "33.3%" : "33.3%")};
    }
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 15px;
    position: relative;
    bottom: 0;
    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #48538d;
    font-size: 14px;
`;
const PaginationCover = styled.div``;
